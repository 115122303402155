class Practitioner {
  public static findUrl = 'api/v1/find_practitioners';
  public static idField = 'practitioner_id';
  public static createUrl = 'api/v1/update_practitioner';
  public static updateUrl = 'api/v1/update_practitioner';
  public static checkUrl = 'api/v1/check_practitioner';

  [x: string]: any;

  constructor(practitionerData: any) {
    Object.assign(this, practitionerData);
  }
}

export default Practitioner;
