import * as React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import { DatePicker } from 'material-ui-pickers';
import { startOfDay } from "date-fns";

import { ClaimFormContext } from './../Pages/EnterClaim/ClaimFormProvider.js';
import AutoCompleteDataProvider from './AutoCompleteDataProvider';


interface RemedyInputRenderProps {
  claim: any,
  disabled: any,
  error: any,
  onChange: any,
  value: any,
}

class RemedyInput extends React.PureComponent<any, any> {
  public render() {
    return this.props.render({
      claim: this.props.claim,
      disabled: this.props.disabled,
      error: this.props.error,
      onChange: this.localOnChange,
      value: this.props.value
    });
  }

  private localOnChange = (e: any) => {
    const preprocess = this.props.valuePreprocess(e);
    this.props.onChange(preprocess.value, this.props.name, preprocess.callback);
  }
}

class RemedyInputWithContext extends React.Component<any ,any> {
  public static contextType = ClaimFormContext;

  public render() {
    const error = this.context.errors.find((err: any) =>
      err.field === this.props.name
    );

    return (
      <RemedyInput
        {...this.props}
        claim={this.context.claim}
        value={this.context.claim[this.props.name]}
        onChange={this.context.onChange}
        error={error}
        disabled={this.props.cond && !this.props.cond(this.context.claim)}
      />
    );
  }
}


const getValuePreprocess = (field: any) => {
  return (input: any) => {
    let value = (field.type === 'select' || field.type === 'text') ?
                  input.target.value :
                  input;
    let callback = () => undefined;

    if (field.type === 'text') {
      const target = input.target;
      const caretStart = target.selectionStart;
      const caretEnd = target.selectionEnd;

      callback = () => {
        target.setSelectionRange(caretStart, caretEnd);
        return undefined;
      };
    }

    if (field.type !== 'date' && field.type !== 'select') {
      if (field.multiple) {
        value = value.map((v: any) => v.toUpperCase());
      } else {
        value = value.toUpperCase();
      }
    }

    if (field.type === 'date' && value !== null) {
      value = startOfDay(value);
    }

    return {
      callback,
      value,
    };
  }
}

const getRenderTextField = (field: any, classes: any) => {
  return (renderProps: RemedyInputRenderProps) => {
    const { value, onChange, error } = renderProps;
    return (
      <TextField
        value={value}
        onChange={onChange}
        error={error ? true : false}
        helperText={error ? error.message : ""}
        fullWidth={true}
        margin="dense"
        multiline={field.multiline}
        label={field.title}
        disabled={field.disabled}
        InputProps={{
          inputRef: field.inputRef,
          ...field.extraProps,
        }}
      />
    );
  };
};

const getRenderDateField = (field: any, classes: any) => {
  return (renderProps: RemedyInputRenderProps) => {
    const { value, onChange, error } = renderProps;
    return (
      <DatePicker
        value={value}
        onChange={onChange}
        className={classes.date}
        keyboard={true}
        label={field.title}
        format="MM/dd/yyyy"
        disableOpenOnEnter={true}
        margin="dense"
        mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
        animateYearScrolling={false}
        disabled={field.disabled}
        clearable={field.clearable}
        error={error ? true : false}
        helperText={error ? error.message : ""}
      />
    );
  };
};

const getRenderSelectField = (field: any, classes: any) => {
  const getOption = (option: any) => {
    return typeof option === "string" ? (
      <option key={option} value={option}>
        {option}
      </option>
    ) : (
      <option key={option.title} value={option.value}>
        {option.title}
      </option>
    );
  };
  return (renderProps: RemedyInputRenderProps) => {
    const { value, onChange } = renderProps;
    return (
      <FormControl fullWidth={true} margin="dense">
        {field.title && <InputLabel>{field.title}</InputLabel>}
        <Select
          native={true}
          input={<Input fullWidth={true} />}
          value={value}
          onChange={onChange}
        >
          {Array.isArray(field.options)
            ? field.options.map((option: any) => getOption(option))
            : Object.keys(field.options).map((group: any) => {
                return (
                  <optgroup key={group} label={group + " Info"}>
                    {field.options[group].map((option: any) =>
                      getOption(option)
                    )}
                  </optgroup>
                );
              })}
        </Select>
      </FormControl>
    );
  };
};

const hscItemRender = (item: any) => {
  return (
    <ListItemText
      primary={item.c}
      secondary={item.d}
    />
  );
};

const hscItemToString = (v: any) => v ? v.c : '';

const getRenderAutoComplete = (field: any, classes: any) => {
  return (renderProps: RemedyInputRenderProps) => {
    const { value, onChange, error, claim } = renderProps;
    return (
      <AutoCompleteDataProvider
        claim={claim}
        value={value}
        onSelect={onChange}
        itemToString={hscItemToString}
        label={field.title}
        itemRender={hscItemRender}
        defaultClear={false}
        multiple={field.multiple}
        dataOptions={field.dataOptions}
        error={error ? true : false}
        helperText={error ? error.message : ""}
        inputRef={field.inputRef}
      />
    );
  };
};

const getWrappedInputField = (field: any, classes: any) => {
  const renderFuncs = {
    'autocomplete' : getRenderAutoComplete(field, classes),
    'date' : getRenderDateField(field, classes),
    'select' : getRenderSelectField(field, classes),
    'text' : getRenderTextField(field, classes),
  };
  const renderFunc = (renderFuncs as any)[field.type];

  return (
    <RemedyInputWithContext
      valuePreprocess={getValuePreprocess(field)}
      render={renderFunc}
      name={field.name}
      cond={field.cond}
    />
  );
};

export const getInputField = (field: any, classes: any) => {
  const renderFuncs = {
    'autocomplete' : getRenderAutoComplete(field, classes),
    'date' : getRenderDateField(field, classes),
    'select' : getRenderSelectField(field, classes),
    'text' : getRenderTextField(field, classes),
  };
  const renderFunc = (renderFuncs as any)[field.type];

  return (
    <RemedyInput
      valuePreprocess={getValuePreprocess(field)}
      render={renderFunc}
      name={field.name}
      cond={field.cond}
      value={field.value}
      onChange={field.onChange}
      error={field.error}
    />
  );
};

export default getWrappedInputField;
