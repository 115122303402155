import * as React from 'react';

import axios from 'axios';

interface IProps<T> {
  contextComponent: any;
  obj: any;
  onFind: any;
  onUpdate: any;
  data: T[];
  newData?: T[];
}

class DataEndpoint<T> extends React.Component<IProps<T>, any> {
  public constructor(props: IProps<T>) {
    super(props);
  }

  public componentWillMount() {
    this.load({});
  }

  public render() {
    const Context = this.props.contextComponent;

    return (
      <Context.Provider value={{
        action: this.action.bind(this),
        check: this.check.bind(this),
        data: this.props.data,
        newData: this.props.newData,
        update: this.update.bind(this),
      }}>
        {this.props.children}
      </Context.Provider>
    );
  }

  private load(args: any) {
    const getPage = (page: number) => {
      if (this.props.data !== null) {
        this.props.onFind(null);
      }

      const maxPage: number = args.maxPage || 
                              this.props.obj.initialFindPages ||
                              -1;

      return axios.post(this.props.obj.findUrl, {
        page,
        ...args,
      }).then((response) => {
        this.props.onFind(
          response.data.map((datum: any) => new this.props.obj(datum))
        );

        if (response.data.length !== 0) {
          if (maxPage === -1 || page < maxPage) {
            getPage(page + 1);
          } else {
            this.props.onFind([]);
          }
        }
      });
    };

    getPage(0);
  }

  private update(newDatum: T) {
    const idField = this.props.obj.idField;
    const isCreate = !(newDatum as any)[idField];
    const url = isCreate ? this.props.obj.createUrl :
                           this.props.obj.updateUrl;

    return axios.post(url, newDatum
    ).then((response) => {
      if (isCreate) {
        (newDatum as any)[idField] = response.data[idField];
      }
      const newDatumObj = new this.props.obj(newDatum, true);

      this.props.onUpdate(newDatumObj, idField);

      return response;
    });
  }

  private check(data: any) {
    return axios.post(this.props.obj.checkUrl, data
    ).then((response) => {
      return response;
    });
  }

  private action(data: any, action: string, callback: any) {
    if (action === 'load') {
      return this.load(data);
    }

    const actionObj = this.props.obj.actions[action];
    const idField = this.props.obj.idField;

    return axios.post(actionObj.url, data
    ).then((response) => {
      const oldDatum = this.props.data.find(
        datum => (datum as any)[idField] === data[idField]
      );
      const newDatum = actionObj.handler(oldDatum, response);

      this.props.onUpdate(newDatum, idField);

      if (callback) {
        callback();
      }

      return response;
    });
  }
}

export default DataEndpoint;
