import React from "react";
import { HotKeys } from "react-hotkeys";

import axios from "axios";

import RemedyAutoComplete from "./RemedyAutoComplete";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import { withStyles } from "@material-ui/core/styles";

import { getInputField } from "./RemedyInput";

const styles = (theme: any) => {
  return {};
};

class AutoCompleteDataProvider extends React.Component<any, any> {
  private isRemote: boolean = false;
  private keyMap: { [key: string]: string } = {};
  private keyHandlers: { [key: string]: (_: any) => void } = {};

  public constructor(props: any) {
    super(props);
    this.isRemote = !!this.props.dataOptions.remoteApi;

    this.keyMap = {
      openDialog: "+",
    };

    this.keyHandlers = {
      openDialog: (e) => {
        e.preventDefault();
        this.handleDialogOpen();
      },
    };

    this.state = {
      data: [],
      dialogOpen: false,
      c: "",
      d: "",
    };
  }

  public componentDidMount() {
    if (this.isRemote) {
      axios
        .post(this.props.dataOptions.remoteApi.get, {})
        .then((response: any) => {
          this.setData(response.data);
        });
    }
  }

  public render() {
    const { dataOptions } = this.props;

    if (this.isRemote) {
      return (
        <React.Fragment>
          {this.getDialog()}
          <HotKeys keyMap={this.keyMap} handlers={this.keyHandlers}>
            <RemedyAutoComplete
              {...this.props}
              itemFilter={this.getFilter(dataOptions)}
            />
          </HotKeys>
        </React.Fragment>
      );
    } else {
      return (
        <RemedyAutoComplete
          {...this.props}
          itemFilter={this.getFilter(dataOptions)}
        />
      );
    }
  }

  private getDialog() {
    const { classes, dataOptions } = this.props;
    const fields = this.isRemote ? dataOptions.remoteApi.fields : [];

    return (
      <Dialog open={this.state.dialogOpen}>
        <DialogTitle>Add</DialogTitle>
        <DialogContent>
          {fields.map((field: any, index: number) => {
            return (
              <div key={index}>
                {getInputField(
                  {
                    value: this.state[field.name],
                    onChange: this.getDialogOnChange(field),
                    ...field,
                  },
                  classes
                )}
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogClose}>Cancel</Button>
          <Button onClick={this.handleDialogAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    );
  }

  private getDialogOnChange = (field: any) => {
    return (value: any) => {
      this.setState({
        [field.name]: value,
      });
    };
  };

  private handleDialogOpen = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  private handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  private handleDialogAdd = () => {
    const code = this.state.c;

    axios
      .post(this.props.dataOptions.remoteApi.add, {
        d: this.state.d,
        c: this.state.c,
      })
      .then((response: any) => {
        this.setState(
          (prevState: any) => {
            const data = prevState.data;
            data.push({
              d: this.state.d,
              c: this.state.c,
            });
            return {
              data: data,
              dialogOpen: false,
              d: "",
              c: "",
            };
          },
          () => {
            this.props.onSelect(response.data.c);
          }
        );
      });
  };

  private getFilter = (options: any) => {
    const defaults = {
      allowSuffix: false,
      dataLength: 5,
      data: this.state.data,
    };

    options = Object.assign({}, defaults, options);

    return (input: any) => {
      const { allowSuffix, dataLength, data } = options;

      let desc_matches: any[] = [];
      let code_matches: any[] = [];
      let exact_code_match: any[] = [];

      if (!input) {
        return [];
      }

      let inputLower = input.toLowerCase();
      let inputWithSuffix = "";

      if (allowSuffix) {
        let match = input.match(/\d{1,2}$/);
        if (match) {
          inputWithSuffix = inputLower;
          inputLower = inputLower.slice(0, 0 - match[0].length);
        } else {
          inputWithSuffix = inputLower;
        }
      }

      let createResult = (hsc: any, isExactMatch: boolean) => {
        if (!allowSuffix || !isExactMatch) {
          return {
            ...hsc,
            cNoSuffix: hsc.c
          };
        }

        return {
          d: hsc.d,
          c: inputWithSuffix.toUpperCase(),
          cNoSuffix: inputLower.toUpperCase(),
        };
      };

      data.forEach((datum: any) => {
        if (datum.c.toLowerCase() === inputLower) {
          exact_code_match.push(createResult(datum, true));
          return;
        }

        if (datum.c.toLowerCase().indexOf(inputLower) !== -1) {
          code_matches.push(createResult(datum, false));
          return;
        }

        if (datum.d.toLowerCase().indexOf(inputLower) !== -1) {
          desc_matches.push(createResult(datum, false));
          return;
        }
      });

      return exact_code_match
        .concat(code_matches, desc_matches)
        .filter(
          (datum) =>
            !this.props.dataOptions.filter ||
            this.props.dataOptions.filter(datum.cNoSuffix, this.props.claim)
        )
        .slice(0, dataLength);
    };
  };

  private setData = (data: any[]) => {
    this.setState({
      data,
    });
  };
}

export default withStyles(styles)(AutoCompleteDataProvider);
