import React, { Component } from "react";

class LoadingPlaceholder extends Component {
  render() {
    return (
      <div />
    );
  }
}

export default LoadingPlaceholder;

