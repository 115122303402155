import * as React from 'react';

import {
  ClaimContext,
  PatientContext,
  PractitionerContext,
  ReferralPractitionerContext,
} from '../Network/DataProvider';

export const withData = (Component: any) => {
  return class DataComponent extends React.Component<any, any> {

    public render() {
      return (
        <ClaimContext.Consumer>
          {(claimEndpoint: any) =>
          <PatientContext.Consumer>
            {(patientEndpoint: any) =>
            <PractitionerContext.Consumer>
              {(practitionerEndpoint: any) =>
              <ReferralPractitionerContext.Consumer>
                  {(referralPractitionerEndpoint: any) =>
                    <Component
                      {...this.props}
                      claimEndpoint={claimEndpoint}
                      patientEndpoint={patientEndpoint}
                      practitionerEndpoint={practitionerEndpoint}
                      referralPractitionerEndpoint={referralPractitionerEndpoint}
                    />
                }
              </ReferralPractitionerContext.Consumer>
              }
            </PractitionerContext.Consumer>
            }
          </PatientContext.Consumer>
          }
        </ClaimContext.Consumer>
      );
    }
  }
};

export default withData;
