import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";

import { ClaimFormContext } from "./ClaimFormProvider.js";
import PersonField, {
  createFieldMetadata,
} from "../../Components/PersonField.js";
import LoadingPlaceholder from "../../Components/LoadingPlaceholder.js";

import { withData } from "../../Components/withData";
import PractitionerFacilitySelect from "./PractitionerFacilitySelect";

const styles = (theme) => {
  return {
    alternativeFacilityContainer: {
      position: "absolute",
      right: "32px",
      bottom: "96px",
      width: "45%",
    },
  };
};

const practitionerFieldMetadata = createFieldMetadata(
  [
    {
      title: "Remedy Practitioner ID",
      shortTitle: "RemPRID",
      name: "practitioner_id",
      disabled: true,
    },
    { title: "Practitioner Name", shortTitle: "Name" },
    { title: "Service Provider PRID", shortTitle: "PRID" },
    { title: "Skill Code", shortTitle: "Skill" },
    { title: "Business Arrangement", shortTitle: "BA" },
    { title: "Facility Number", shortTitle: "Fac" },
    { title: "Functional Center", shortTitle: "Func" },
    { title: "Location Code", shortTitle: "Loc" },
  ].map((prac) => {
    return {
      outerDisplay: prac.shortTitle ? true : false,
      ...prac,
    };
  })
);

const getPractitionerMetadata = (filter) => {
  return {
    title: "Practitioner",

    fields: practitionerFieldMetadata,

    primaryField: practitionerFieldMetadata[2],
    keyField: practitionerFieldMetadata[0],

    validateNewPrimary: (newPrimaryField) => {
      return newPrimaryField.length === 9;
    },

    filter: filter,

    render: (practitioner) => {
      let getItem = (primary, secondary) => {
        return (
          <Grid item xs={6}>
            <ListItemText primary={primary} secondary={secondary} />
          </Grid>
        );
      };

      return (
        <Grid container>
          {getItem(
            practitioner.practitioner_name,
            practitioner.service_provider_prid
          )}
        </Grid>
      );
    },
  };
};

class PractitionerForm extends Component {
  static contextType = ClaimFormContext;

  constructor(props) {
    super(props);

    this.practitionerMetadata = getPractitionerMetadata(
      this.practitionerFilter
    );
  }

  onAdd = (practitioner) => {
    this.props.onAdd(practitioner);
  };

  onEdit = (newPractitioner) => {
    this.props.onAdd(newPractitioner);
  };

  practitionerFilter = (input) => {
    let count = 0;

    return this.props.practitionerEndpoint.data.filter((practitioner) => {
      const keep =
        (!input ||
          practitioner.practitioner_name
            .toLowerCase()
            .indexOf(input.toLowerCase()) !== -1 ||
          practitioner.service_provider_prid.toString().indexOf(input) !==
            -1) &&
        count < 5;

      if (keep) {
        count += 1;
      }

      return keep;
    });
  };

  onChange = (practitioner) => {
    return this.context.onChange(practitioner, "practitioner");
  };

  render() {
    return (
      <div>
        {this.props.practitionerEndpoint.data === [] ? (
          <LoadingPlaceholder />
        ) : (
          <React.Fragment>
            <PersonField
              errors={this.context.practitionerErrors}
              check={this.props.patientEndpoint.check}
              update={this.props.practitionerEndpoint.update}
              itemMetadata={this.practitionerMetadata}
              inputRef={this.props.inputRef}
              onAdd={this.onAdd}
              onEdit={this.onEdit}
              onChange={this.onChange}
              value={this.context.practitioner}
            />
            <div className={this.props.classes.alternativeFacilityContainer}>
              <PractitionerFacilitySelect
                practitioner={this.context.practitioner}
                updateClaim={this.context.onChange}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withData(PractitionerForm));
