import React, { PureComponent } from "react";
import { HotKeys } from 'react-hotkeys';

import { withStyles } from "@material-ui/core/styles";

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';

import ErrorSnackbar from './ErrorSnackbar';
import RemedyAutoComplete from './RemedyAutoComplete.js';
import { getInputField } from './RemedyInput';

const styles = theme => ({
  root: {
    position: 'relative',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  buttons: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
  date: {
    width: '100%'
  },
});

const DialogState = {
  Closed: 0,
  Filling: 1,
  Sending: 2
};

function debounce(fn, delay) {
  var timer = null;
  return function() {
    let context = this, args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

export const createFieldMetadata = (metadata) => {
  return metadata.map((field) => {
    if (!field.virtual) {
      field.virtual = false;
    }
    if (!field.disabled) {
      field.disabled = false;
    }
    if (!field.type) {
      field.type = 'text';
    }
    if (!field.name) {
      field.name = field.title.toLowerCase().replace(/ /g, '_');
    }
    if (!field.fromData) {
      field.fromData = (item, empty = '') => {
        if (item && item[field.name])
          return item[field.name];
        return empty;
      };
    }
    return field;
  });
}

class PersonField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dialogPerson: this.getPersonData(props.itemMetadata.fields),
      dialogErrors: [],
      isEditDialog: false,
      dialogState: DialogState.Closed,
    };

    this.dialogBtnRef = null;
    this.setDialogBtnRef = (ref) => { this.dialogBtnRef = ref; };

    this.checkPerson = debounce(this.checkPerson, 300);
  }

  getPersonData = (fieldMetadata, currentPerson = {}) => {
    let result = {};
    fieldMetadata.forEach((field) => {
      if (field.virtual === true)
        return;

      if (currentPerson[field.name]) {
        result[field.name] = currentPerson[field.name];
      } else {
        switch (field.type) {
          case 'select':
            result[field.name] = field.options[0];
            break;
          case 'date':
            if (!field.clearable) {
              result[field.name] = new Date();
            } else {
              result[field.name] = null;
            }
            break;
          default:
            result[field.name] = '';
            break;
        }
      }
    });
    return result;
  }

  onSelect = (data) => {
    if (data) {
      this.props.onChange(data);
    }
  }

  onClear = () => {
    this.props.onChange(null);
  }

  onNewItemAdd = (value) => {
    const { itemMetadata } = this.props;
    if (itemMetadata.validateNewPrimary(value)) {
      const newData = {};
      newData[itemMetadata.primaryField.name] = value;
      this.setState({
        isEditDialog: false,
        dialogPerson: this.getPersonData(this.props.itemMetadata.fields,
                                         newData),
        dialogState: DialogState.Filling,
      });
    }
  }

  handleDialogOpen = () => {
    this.setState({
      isEditDialog: false,
      dialogPerson: this.getPersonData(this.props.itemMetadata.fields),
      dialogState: DialogState.Filling,
    });
  }

  handleDialogOpenEdit = () => {
    this.setState({
      isEditDialog: true,
      dialogPerson: this.getPersonData(this.props.itemMetadata.fields,
                                       this.props.value),
      dialogState: DialogState.Filling,
    });
  }

  handleDialogAdd = () => {
    let newPerson = this.state.dialogPerson;
    let isEdit = this.state.isEditDialog;

    this.setState({
      dialogState: DialogState.Sending
    }, () => {
      this.props.update(newPerson)
      .then((response) => {
        newPerson[this.props.itemMetadata.keyField.name] =
          response.data[this.props.itemMetadata.keyField.name];

        this.setState({
          dialogPerson: this.getPersonData(this.props.itemMetadata.fields),
          dialogState: DialogState.Closed
        }, () => {
          if (isEdit) {
            this.props.onEdit(newPerson);
          } else {
            this.props.onAdd(newPerson);
          }
          this.props.onChange(newPerson);
        });
      })
      .catch((response) => {
        this.setState({
          dialogState : DialogState.Filling
        });
      });
    });
  }

  handleDialogClose = () => {
    this.setState({ dialogState: DialogState.Closed });
  }

  keyMap = {
    openDialog: '+',
    closeDialog: 'esc',
    addDialog: 'enter',
  }

  keyHandlers = {
    openDialog: (e) => {
      e.preventDefault();
      this.handleDialogOpen();
    },
    closeDialog: (e) => {
      e.preventDefault();
      this.handleDialogClose();
    },
    addDialog: (e) => {
      e.preventDefault();
      if (this.state.dialogState === DialogState.Filling) {
        this.dialogBtnRef.focus();
        setTimeout(this.handleDialogAdd, 0);
      }
    }
  }

  checkPerson = (person) => {
    this.props.check(person)
    .then(response => {
      const result = response.data;
      this.setState({
        dialogErrors: result.errors
      });
    });
  };

  getDialogOnChange = (field) => {
    return (value) => {
      this.setState((prevState) => {
        let dialogPersonState = Object.assign({}, prevState.dialogPerson);
        dialogPersonState[field.name] = value;
        this.checkPerson(dialogPersonState);
        return {
          dialogPerson: dialogPersonState
        };
      });
    };
  };

  getDialogField = (field, classes, extraProps) => {
    const value = this.state.dialogPerson[field.name];
    const error = this.state.dialogErrors.find((err) =>
      err.field === field.name
    );

    return getInputField({
      value : value,
      onChange : this.getDialogOnChange(field),
      error : error,
      extraProps : extraProps,
      ...field,
    }, classes);
  };


  render() {
    let {
      classes,
      itemMetadata,
    } = this.props;

    return (
      <div>
        <Dialog
          open={this.state.dialogState !== DialogState.Closed}
          disableEnforceFocus={this.state.dialogState === DialogState.Closed}
          disableRestoreFocus={true}
        >
          <DialogTitle>
            {(this.state.isEditDialog ? 'Edit ' : 'Add ') +
             itemMetadata.title}
          </DialogTitle>
          <DialogContent>
            <HotKeys keyMap={this.keyMap} handlers={this.keyHandlers}>
              {itemMetadata.fields.filter((item) => {
                return item.virtual === false;
              }).map((field, index) =>
                <div key={index}>
                  { this.getDialogField(
                      field,
                      classes,
                      index === 1 ? { autoFocus: true } : {}
                  )}
                </div>
              )}
            </HotKeys>
          </DialogContent>
          <DialogActions>
            {this.state.dialogState !== DialogState.Sending && (
              <Button onClick={this.handleDialogClose}>
                Cancel
              </Button>
            )}
            <Button
              onClick={this.handleDialogAdd}
              buttonRef={this.setDialogBtnRef}
            >
              {this.state.dialogState === DialogState.Sending ?
                ('Sending...') : (this.state.isEditDialog ? 'Edit' : 'Add')}
            </Button>
          </DialogActions>
        </Dialog>
        <HotKeys keyMap={this.keyMap} handlers={this.keyHandlers}>
          <ErrorSnackbar
            errors={this.props.errors}
          />
          <Paper className={classes.root}>
            <Typography variant='h5'>
              { itemMetadata.title }
            </Typography>
            {itemMetadata.fields.filter((item) => {
              return item.outerDisplay;
            }).map((item, index) => (
                <Typography variant='body1' key={index}>
                  { item.shortTitle + ': ' + item.fromData(this.props.value, 'None') }
                </Typography>
              )
            )}
            <div className={classes.buttons}>
              <IconButton onClick={this.handleDialogOpen} tabIndex='-1' aria-label="add" className={classes.add}>
                <AddIcon />
              </IconButton>
              {this.props.value !== null && (
                <IconButton onClick={this.handleDialogOpenEdit} tabIndex='-1' aria-label="edit" className={classes.edit}>
                  <EditIcon />
                </IconButton>
              )}
              {this.props.value !== null && this.props.canClear && (
                  <IconButton onClick={this.onClear} tabIndex='-1' aria-label="clear" className={classes.clear}>
                    <CloseIcon />
                  </IconButton>
              )}
            </div>
            <RemedyAutoComplete
              inputRef={this.props.inputRef}
              onSelect={this.onSelect}
              itemToString={itemMetadata.primaryField.fromData}
              itemFilter={itemMetadata.filter}
              placeholder={'Find a ' + itemMetadata.title.toLowerCase() + '...'}
              itemRender={itemMetadata.render}
              defaultClear={true}
              onNewItemAdd={this.onNewItemAdd}
            />
          </Paper>
        </HotKeys>
      </div>
    );
  }

}

export default withStyles(styles)(PersonField);

