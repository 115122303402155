import React, { Component, PureComponent } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

export default class RemedyDialog extends Component<any, any> {
  public constructor(props: any) {
    super(props);

    this.state = {
      sending: false,
    };
  }

  public componentDidUpdate(prevProps: any) {
    if (prevProps.open === true && this.props.open === false) {
      this.setState({
        sending: false
      });
    }
  }

  public render() {
    return (
      <Dialog
        open={this.props.open}
        disableEnforceFocus={this.state.sending === false}
        disableRestoreFocus={true}
      >
        <DialogTitle>{this.props.title}</DialogTitle>
        <DialogContent>{this.props.children}</DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogClose}>Cancel</Button>
          <Button onClick={this.handleDialogSave}>
            {this.state.sending ? "Sending..." : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private handleDialogClose = () => {
    this.props.onClose();
  };

  private handleDialogSave = () => {
    this.setState(
      {
        sending: true,
      },
      () => {
        this.props.onSave(this.cleanupCallback);
      }
    );
  };

  private cleanupCallback = () => {
    this.setState({
      sending: false,
    });
  };
}
