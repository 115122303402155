import React, { Component } from 'react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';

const styles = {
  paper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  }
};

class Ahs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      snackbarMessage: '',
      snackbarOpen: false
    }
  }

  openSnackbar = (message) => {
    this.setState({
      snackbarMessage: message,
      snackbarOpen: true,
    });
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbarOpen: false,
    });
  }

  onSubmit = () => {
    axios.post('/api/v1/trigger_ahs', {
    }).then((response) => {
      this.openSnackbar('Ahs Polled Successfully');
    }).catch((err) => {
      this.openSnackbar('Error Polling Ahs');
    });
  }


  render() {
    let { classes } = this.props;

    return (
      <div>
        <Paper className={classes.paper}>
          <Typography variant='h5'>
            Assessment
          </Typography>
          <Button onClick={this.onSubmit}>
            Submit
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.snackbarOpen}
            autoHideDuration={5000}
            onClose={this.handleSnackbarClose}
            message={this.state.snackbarMessage}
            action={(
              <IconButton
                aria-label='Close'
                color='inherit'
                className={classes.close}
                onClick={this.handleSnackbarClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          />
          </Paper>
      </div>
    );
  }
};

export default withStyles(styles)(Ahs);
