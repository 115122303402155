import * as React from 'react';

import SnackbarContent from '@material-ui/core/SnackbarContent';

import { withStyles } from "@material-ui/core/styles";

import ErrorIcon from '@material-ui/icons/Error';

const styles = (theme: any) => ({
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
    minWidth: 'inherit',
  },
  errorSnackbarMessage: {
    alignItems: 'center',
    display: 'flex',
  }
});

class ErrorSnackbar extends React.Component<any, any> {
  public render() {
    const { classes, ...other } = this.props;
    
    return (
      <div>
        { this.props.errors.length > 0 &&
          <SnackbarContent
            className={classes.errorSnackbar}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.errorSnackbarMessage}>
                <ErrorIcon />
                {this.props.errors[0].message}
              </span>
            }
            {...other}
          />
        }
      </div>
    );
  }
}

export default withStyles(styles)(ErrorSnackbar);
