import React, { Component } from "react";
import axios from "axios";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import WarningIcon from "@material-ui/icons/Warning";

import ErrorSnackbar from "../../Components/ErrorSnackbar";

const styles = (theme) => ({
  root: {
    paddingTop: "16px",
    paddingLeft: "16px",
    paddingRight: "36px",
  },
  paper: {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  noticeHeader: {
    display: "flex",
    alignItems: "center",
  },
  noticeIcon: {
    paddingRight: "8px",
  },
  notice: {
    backgroundColor: "#ffdd99",
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  section: {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      two_factor: "",
      errors: [],
    };
  }

  getOnChange = (value) => {
    return (e) => {
      let newValue = e.target.value;
      this.setState((prevState) => {
        prevState[value] = newValue;
        prevState.errors = [];
        return prevState;
      });
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/v1/login", {
        username: this.state.username,
        password: this.state.password,
        totp_token: this.state.two_factor,
      })
      .then((response) => {
        this.props.onAuthenticate(response.data.is_super_user);
      })
      .catch((response) => {
        this.setState({
          errors: [{ field: "login", message: "Login information incorrect" }],
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} md={2} className={classes.section}>
          <ErrorSnackbar errors={this.state.errors} />

          <Paper className={classes.paper}>
            <Typography variant="h5">Login</Typography>
            <form onSubmit={this.handleSubmit}>
              {[
                { label: "Username", value: "username", type: "text" },
                { label: "Password", value: "password", type: "password" },
                {
                  label: "Two Factor (if enabled)",
                  value: "two_factor",
                  type: "text",
                },
              ].map((settings, index) => {
                return (
                  <Grid container key={index}>
                    <TextField
                      label={settings.label}
                      value={this.state[settings.value]}
                      onChange={this.getOnChange(settings.value)}
                      type={settings.type}
                      fullWidth
                    ></TextField>
                  </Grid>
                );
              })}
              <Button fullWidth type="submit">
                Login
              </Button>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={12} md={2} className={classes.section}>
          <Paper className={classes.notice}>
            <Typography className={classes.noticeHeader} variant="h6">
              <WarningIcon className={classes.noticeIcon} />
              NOTICE
            </Typography>
            <Typography variant="body1">
              remedyapps.ca is a secure site for authorized users only. By
              logging on, you acknowledge that you are authorized to use this
              service for medical billing submission and reporting. You are
              accepting all responsibility for events associated with your
              unique login ID that may occur during your active session. Medical
              information is sensitive and privileged. Leaving a device
              unattended while logged on to remedyapps.ca is dangerous and
              unacceptable. Remedy Applications Inc. (The owner/operator of
              Remedy Billing and remedyapps.ca) will not be held responsible for
              information breaches that are the result of user negligence.
              Please remember to end your session by clicking "LOGOUT"
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Login);
