import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import ListItemText from '@material-ui/core/ListItemText';

import { ClaimFormContext } from './ClaimFormProvider.js';
import PersonField, { createFieldMetadata } from '../../Components/PersonField.js';
import LoadingPlaceholder from '../../Components/LoadingPlaceholder.js';

import { withData } from '../../Components/withData';

const styles = theme => ({
});

const patientFieldMetadata = createFieldMetadata([
  { title: 'Remedy Patient ID', name: 'patient_id', shortTitle: 'RemPID', outerDisplay: true, disabled: true, },
  {
    title: 'Full Name',
    shortTitle: 'Name',
    fromData: (patient, empty = '') =>
      patient ? patient.first_name + ' ' + patient.surname : empty,
    virtual: true,
    outerDisplay: true,
  },
  { title: 'Patient Health Number', shortTitle: 'PHN', outerDisplay: true },
  { title: 'PHN Province', type: 'select', options: [ 'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT']},
  { title: 'First Name', },
  { title: 'Middle Name' },
  { title: 'Last Name', name: 'surname' },
  { title: 'Gender', type: 'select', options: ["MALE", "FEMALE"]},
  { title: 'Street Address' },
  { title: 'City' },
  { title: 'Province', type: 'select', options: [ 'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT']},
  { title: 'Postal Code' },
  { title: 'Birthdate', type: 'date', clearable: true },
  { title: 'Guardian Patient Health Number', shortTitle: 'Guardian PHN' },
  { title: 'Guardian PHN Province', type: 'select', options: [ '', 'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT']},
]);

const primaryPatientField = patientFieldMetadata[2];
const keyPatientField = patientFieldMetadata[0];

const getPatientMetadata = (filter) => {
  return {
    title : 'Patient',

    fields : patientFieldMetadata,

    primaryField : primaryPatientField,
    keyField : keyPatientField,

    validateNewPrimary : (newPrimaryField) => {
      return newPrimaryField.length === 9;
    },

    filter : filter,

    render : (patient) => {
      return (
        <ListItemText
          primary={patientFieldMetadata[1].fromData(patient)}
          secondary={patientFieldMetadata[2].fromData(patient)}
        />
      );
    },
  };
};

class PatientForm extends Component {
  static contextType = ClaimFormContext;

  constructor(props) {
    super(props);

    this.patientMetadata = getPatientMetadata(this.patientFilter);
  }
  
  onAdd = (patient) => {
    this.props.onAdd(patient);
  }

  onEdit = (newPatient) => {
    this.props.onAdd(newPatient);
  }
  
  patientFilter = (input) => {
    let count = 0;

    return this.props.patientEndpoint.data.filter(patient => {
      const uc_input = input.toUpperCase();
      const name = patient.first_name + ' ' + patient.surname;
      const phn = patient.patient_health_number;
      const keep = (count < 5) && (!input ||
        [
          name, phn
        ].reduce((last, field) => {
          if (field) {
            return last || field.toString().indexOf(uc_input) !== -1;
          }
          return last;
        }, false)
      );

      if (keep) {
        count += 1;
      }

      return keep;

    });
  };

  onChange = (patient) => {
    return this.context.onChange(patient, 'patient');
  }

  render() {
    return (
      <div>
        { this.props.patientEndpoint.data === [] ? (
          <LoadingPlaceholder />
        ) : (
          <PersonField
            errors={this.context.patientErrors}
            check={this.props.patientEndpoint.check}
            update={this.props.patientEndpoint.update}
            itemMetadata={this.patientMetadata}
            inputRef={this.props.inputRef}
            onAdd={this.onAdd}
            onEdit={this.onEdit}
            onChange={this.onChange}
            value={this.context.patient}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withData(PatientForm));
