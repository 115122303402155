import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import ListItemText from '@material-ui/core/ListItemText';

import { ClaimFormContext } from './ClaimFormProvider.js';
import PersonField, { createFieldMetadata } from '../../Components/PersonField.js';
import LoadingPlaceholder from '../../Components/LoadingPlaceholder.js';

import { withData } from '../../Components/withData';

const styles = theme => ({
});

const referralPractitionerFieldMetadata = createFieldMetadata([
  {
    title: 'Remedy Referral Practitioner ID',
    shortTitle: 'RemPRID',
    name: 'referral_practitioner_id',
    disabled: true,
  },
  {
    title: 'Full Name',
    shortTitle: 'Name',
    fromData: (patient, empty = '') =>
      patient ? patient.first_name + ' ' + patient.surname : empty,
    virtual: true,
    outerDisplay: true,
  },
  { title: 'Service Provider PRID', shortTitle: 'PRID', outerDisplay: true },
  { title: 'First Name', },
  { title: 'Last Name', name: 'surname' },
  { title: 'Street Address' },
  { title: 'City' },
  { title: 'Province', type: 'select', options: [ 'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT']},
  { title: 'Postal Code' },
]);

const getMetadata = (filter) => {
  return {
    title : 'OOP Referral Info',

    fields : referralPractitionerFieldMetadata,

    primaryField : referralPractitionerFieldMetadata[2],
    keyField : referralPractitionerFieldMetadata[0],

    validateNewPrimary : (newPrimaryField) => {
      return newPrimaryField.length === 9;
    },

    filter : filter,

    render : (referralPractitioner) => {
      return (
        <ListItemText
          primary={referralPractitionerFieldMetadata[1].fromData(referralPractitioner)}
          secondary={referralPractitionerFieldMetadata[2].fromData(referralPractitioner)}
        />
      );
    },
  };
};

class ReferralPractitionerForm extends Component {
  static contextType = ClaimFormContext;

  constructor(props) {
    super(props);

    this.state = {
      metadata : getMetadata(this.filter),
    };
  }

  onAdd = (refPrac) => {
    this.props.onAdd(refPrac);
  }

  onEdit = (newRefPrac) => {
    this.props.onAdd(newRefPrac);
  }
  
  filter = (input) => {
    let count = 0;

    return this.props.referralPractitionerEndpoint.data.filter(refPrac => {
      const uc_input = input.toUpperCase();
      const name = refPrac.first_name + ' ' + refPrac.surname;
      const prid = refPrac.service_provider_prid;
      const keep = (count < 5) && (!input ||
        [
          name, prid
        ].reduce((last, field) => {
          if (field) {
            return last || field.toString().indexOf(uc_input) !== -1;
          }
          return last;
        }, false)
      );

      if (keep) {
        count += 1;
      }

      return keep;
    });
  };

  onChange = (refPrac) => {
    return this.context.onChange(refPrac, 'referralPractitioner');
  }

  render() {
    return (
      <div>
        { this.props.practitionerEndpoint.data === [] ? (
          <LoadingPlaceholder />
        ) : (
          <PersonField
            errors={this.context.referralPractitionerErrors}
            check={this.props.patientEndpoint.check}
            update={this.props.referralPractitionerEndpoint.update}
            itemMetadata={this.state.metadata}
            inputRef={this.props.inputRef}
            onAdd={this.onAdd}
            onEdit={this.onEdit}
            onChange={this.onChange}
            value={this.context.referralPractitioner}
            canClear={true}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(withData(ReferralPractitionerForm));
