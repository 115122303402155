import { ymdToMdy } from '../Components/Common';

const actionResponse = (claim: Claim, response: any) => {
  claim.state = response.data.new_state;
  return claim;
};

const acceptClaimBalance = (claim: Claim, response: any) => {
  claim.balance_owing = 0;
  return claim;
};

const getSubmissionHistory = (claim: Claim, response: any) => {
  claim.ClaimResponses = response.data;
  claim.ClaimResponses.forEach((claimResponse: any) => {
    claimResponse.expected_payment_date = ymdToMdy(claimResponse.expected_payment_date);
    claimResponse.assessment_date = ymdToMdy(claimResponse.assessment_date);
  });
  return claim;
};

class Claim {
  public static findUrl = 'api/v1/find_claims';
  public static initialFindPages = 1;
  public static idField = 'claim_id';
  public static updateUrl = 'api/v1/update_claim';
  public static createUrl = 'api/v1/create_claim';
  public static actions = {
    accept_claim: {
      handler: acceptClaimBalance,
      url: 'api/v1/accept_claim_balance'
    },
    cancel: {
      handler: actionResponse,
      url: 'api/v1/action_claim',
    },
    'delete': {
      handler: actionResponse,
      url: 'api/v1/action_claim',
    },
    history: {
      handler: getSubmissionHistory,
      url: 'api/v1/submission_history',
    },
    reassess: {
      handler: actionResponse,
      url: 'api/v1/action_claim',
    },
    submit: {
      handler: actionResponse,
      url: 'api/v1/action_claim',
    },
  };

  public ClaimResponses: any;
  public Patient: any;
  public Practitioner: any;

  public hospital_admission_date?: string;
  public service_start_date?: string;
  public createdAt?: string;
  public updatedAt?: string;
  public claim_id_override?: string;
  public state?: number;

  [x: string]: any;

  constructor(claimData: any) {
    Object.assign(this, claimData);

    this.ClaimResponses = [];

    if (this['ClaimResponses.sequence_number'] === this.claim_id) {
      this.ClaimResponses.push({});
    }

    for (const k of Object.keys(claimData)) {
      if (this[k] === null) {
        this[k] = '';
      }

      if (k.startsWith('ClaimResponses.')) {
        if (this.ClaimResponses.length !== 0) {
          const keySplit = k.split('.');
          this.ClaimResponses[0][keySplit[1]] = this[k];
        }
        delete this[k];
      }
    }

    this.ClaimResponses.forEach((claimResponse: any) => {
      claimResponse.expected_payment_date = ymdToMdy(claimResponse.expected_payment_date);
      claimResponse.assessment_date = ymdToMdy(claimResponse.assessment_date);
    });

    if (this.hospital_admission_date) {
      this['hospital_admission_date'] = ymdToMdy(this.hospital_admission_date);
    }

    this.service_start_date = ymdToMdy(this.service_start_date);

    if (this.createdAt) {
      this.createdAt = ymdToMdy(this.createdAt);
    }

    if (this.updatedAt) {
      this.updatedAt = ymdToMdy(this.updatedAt);
    }

    if (this.state == null) {
      if (this.claim_id_override) {
        this.state = 4;
      } else {
        this.state = 0;
      }
    }
  }
}

export default Claim;
