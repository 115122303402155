import * as React from 'react';

import DataEndpoint from './DataEndpoint';

import Claim from './Claim';
import Patient from './Patient';
import Practitioner from './Practitioner';
import ReferralPractitioner from './ReferralPractitioner';

export type ClaimEndpoint = new () => DataEndpoint<Claim>;
export type PatientEndpoint = new () => DataEndpoint<Patient>;
export type PractitionerEndpoint = new () => DataEndpoint<Practitioner>;
export type ReferralPractitionerEndpoint = new () => DataEndpoint<ReferralPractitioner>;

export const ClaimEndpoint = DataEndpoint as ClaimEndpoint;
export const PatientEndpoint = DataEndpoint as PatientEndpoint;
export const PractitionerEndpoint = DataEndpoint as PractitionerEndpoint;
export const ReferralPractitionerEndpoint = DataEndpoint as ReferralPractitionerEndpoint;

export const ClaimContext = React.createContext([]);
export const PatientContext = React.createContext([]);
export const PractitionerContext = React.createContext([]);
export const ReferralPractitionerContext = React.createContext([]);

class DataProvider extends React.Component<any, any> {
  public constructor(props: any) {
    super(props);

    this.state = {
      claims: null,
      newClaims: [],
      newPatients: [],
      newPractitioners: [],
      newReferralPractitioners: [],

      partialClaims: [],
      partialPatients: [],
      partialPractitioners: [],
      partialReferralPractitioners: [],

      patients: null,
      practitioners: null,
      referralPractitioners: null,
    };
  }

  public getOnFind(field: string, partialField: string) {
    return (newData: any) => {
      this.setState((prevState: any) => {
        if (newData === null) {
          prevState[partialField] = [];
          prevState[field] = null;

          return prevState;
        }

        if (newData.length !== 0) {
          prevState[partialField] = prevState[partialField].concat(newData);
        } else {

          prevState[field] = prevState[partialField];

          const { claims, patients, practitioners, referralPractitioners } = prevState;

          if (claims !== null &&
              patients !== null &&
              practitioners !== null &&
              referralPractitioners !== null) {
            const patientsRef = {};
            const practitionersRef = {};
            const referralPractitionersRef = {};
            for (const patient of patients) {
              (patientsRef as any)[patient.patient_id] = patient;
            }
            for (const practitioner of practitioners) {
              (practitionersRef as any)[practitioner.practitioner_id] = practitioner;
            }
            for (const referralPractitioner of referralPractitioners) {
              (referralPractitionersRef as any)[referralPractitioner.referral_practitioner_id] = referralPractitioner;
            }

            prevState.claims = claims.reduce((filteredClaims: any, claim: any) => {
              const result = this.associateClaimFastViaKeys(claim, patientsRef, practitionersRef, referralPractitionersRef);

              if (result !== null) {
                filteredClaims.push(result);
              }
              return filteredClaims;
            }, []);
          }
        }

        return prevState;
      });
    };
  }

  public getOnUpdate(field: string, newField: string) {
    return (newDatum: any, idField: string) => {
      this.setState((prevState: any) => {
        const data = prevState[field];
        const newData = prevState[newField];
        const index = data.findIndex((datum: any) =>
          datum[idField] === newDatum[idField]
        ); 

        if (newDatum instanceof Claim) {
          newDatum = this.associateClaim(
            newDatum,
            prevState.patients,
            prevState.practitioners,
            prevState.referralPractitioners
          );
        }

        if (index === -1) {
          data.push(newDatum);
        } else {
          data[index] = newDatum;
        }

        const newDataIndex = prevState[newField].findIndex((datum: any) =>
          datum[idField] === newDatum[idField]
        );

        if (newDataIndex === -1) {
          newData.push(newDatum);
        } else {
          newData[newDataIndex] = newDatum;
        }

        return {
          [field] : data,
          [newField] : newData,
        };
      });
    };
  }

  public render() {
    const data = this.state;

    return (
      <React.Fragment>
        <ClaimEndpoint
          data={data.claims}
          newData={data.newClaims}
          onFind={this.getOnFind('claims', 'partialClaims')}
          onUpdate={this.getOnUpdate('claims', 'newClaims')}
          contextComponent={ClaimContext}
          obj={Claim}
        >
          <PatientEndpoint
            data={data.patients}
            onFind={this.getOnFind('patients', 'partialPatients')}
            onUpdate={this.getOnUpdate('patients', 'newPatients')}
            contextComponent={PatientContext}
            obj={Patient}
          >
            <PractitionerEndpoint
              data={data.practitioners}
              onFind={this.getOnFind('practitioners', 'partialPractitioners')}
              onUpdate={this.getOnUpdate('practitioners', 'newPractitioners')}
              contextComponent={PractitionerContext}
              obj={Practitioner}
            >
              <ReferralPractitionerEndpoint
                data={data.referralPractitioners}
                onFind={this.getOnFind('referralPractitioners', 'partialReferralPractitioners')}
                onUpdate={this.getOnUpdate('referralPractitioners', 'newReferralPractitioners')}
                contextComponent={ReferralPractitionerContext}
                obj={ReferralPractitioner}
              >
                {this.props.children}
              </ReferralPractitionerEndpoint>
            </PractitionerEndpoint>
          </PatientEndpoint>
        </ClaimEndpoint>
      </React.Fragment>
    );
  }

  private associateClaim(
    claim: Claim,
    patients: Patient[],
    practitioners: Practitioner[],
    referralPractitioners: ReferralPractitioner[]
  ) {
    claim.Patient = patients.find((patient: any) =>
      patient.patient_id === claim.remedy_patient_id);
    claim.Practitioner = practitioners.find((practitioner: any) =>
      practitioner.practitioner_id === claim.remedy_practitioner_id);
    claim.ReferralPractitioner = referralPractitioners.find((referralPractitioner: any) =>
      referralPractitioner.referral_practitioner_id === claim.remedy_referral_practitioner_id);
    if (claim.Patient == null || claim.Practitioner == null) {
      return null;
    }
    return claim;
  }

  // This method associates claims using pre-baked objects where the ID is
  // an object key associated with the patient or practitioner
  private associateClaimFastViaKeys(
    claim: Claim,
    patients: any,
    practitioners: any,
    referralPractitioners: any
  ) {
    claim.Patient = patients[claim.remedy_patient_id];
    claim.Practitioner = practitioners[claim.remedy_practitioner_id];
    claim.ReferralPractitioner = referralPractitioners[claim.remedy_referral_practitioner_id];
    if (claim.Patient == null || claim.Practitioner == null) {
      return null;
    }
    return claim;
  }
}

export default DataProvider;
