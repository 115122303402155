import { format } from 'date-fns';
import _ from 'underscore';

import RemedyInput from './RemedyInput.tsx';

import denseExp from '../Data/denseExp.json';

const finalizeMetadatum = (datum, additionalFields) => {
  if (additionalFields) {
    datum = Object.assign(additionalFields, datum);
  }

  if (!datum.name) {
    datum.name = datum.title.toLowerCase().replace(/ /g, '_');
  }

  if (!datum.short_title) {
    datum.short_title = datum.title;
  }

  if (!datum.type) {
    datum.type = 'text';
  }

  if (!datum.numeric) {
    datum.numeric = false;
  }

  if (!datum.fromClaim) {
    if (!datum.subobj) {
      datum.fromClaim = (claim) => claim[datum.name];
    } else {
      if (!datum.multi)  {
        datum.fromClaim = (claim) => claim[datum.subobj][datum.name];
      } else {
        datum.fromClaim = (claim, index) => {
          index = index || 0;
          if (claim[datum.subobj].length === 0)
            return "";

          if (datum.fromClaimResponse) {
            return datum.fromClaimResponse(claim, index);
          }
          return claim[datum.subobj][index][datum.name];
        }
      }
    }
  }

  return datum;
};

const sortMetadata = (a, b) => {
  let name_a = a.name.toUpperCase();
  let name_b = b.name.toUpperCase();

  if (name_a < name_b) {
    return -1;
  }

  if (name_a > name_b) {
    return 1;
  }

  return 0;
};

const intToPrice = (price) => {
  price = price || 0;
  let dollars = Math.floor(price / 100);
  let cents = (Math.abs(price) % 100).toString().padEnd(2, '0');
  return dollars + '.' + cents;
};

const ymdToMdy = (date) => {
  if (_.isString(date)) {
    return date.substr(5, 5) + '-' + date.substr(0, 4);
  } else {
    return format(date, 'MM-dd-yyyy');
  }
}

const getInputField = (field, classes) => {
  return RemedyInput(field, classes);
};

const kPatientMetadata = [
  {
    title: 'Remedy Patient ID',
    short_title: 'RemPID',
    name: 'patient_id',
    disabled: true,
  },
  {
    title: 'Patient Name',
    short_title: 'Pt Name',
    fromClaim: claim => claim.Patient.surname + ', ' + claim.Patient.first_name,
  },
  {
    title: 'Patient Health Number',
    short_title: 'PHN',
  },
  {
    title: 'PHN Province',
    type: 'select',
    options: [ 'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'],
  },
  { title: 'First Name', },
  { title: 'Middle Name' },
  { title: 'Last Name', name: 'surname' },
  { title: 'Gender', type: 'select', options: ["MALE", "FEMALE"]},
  { title: 'Street Address' },
  { title: 'City' },
  { title: 'Province', type: 'select', options: [ 'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT']},
  { title: 'Postal Code' },
  { title: 'Birthdate', type: 'date', clearable: true },
].map(datum => finalizeMetadatum(datum, { subobj: 'Patient' })).sort(sortMetadata);

const kPractitionerMetadata = [
  {
    title: 'Remedy Practitioner ID',
    short_title: 'RemPRID',
    name: 'practitioner_id',
    disabled: true,
  },
  {
    title: 'Practitioner Name',
    short_title: 'Dr Name',
  },
  { title: 'Service Provider PRID', short_title: 'PRID', },
  { title: 'Skill Code', short_title: '(Pr)Skill' },
  { title: 'Business Arrangement', short_title: '(Pr)BA', },
  { title: 'Facility Number', short_title: '(Pr)Fac', },
  { title: 'Functional Center', short_title: '(Pr)Func', },
  { title: 'Location Code', short_title: '(Pr)Loc', },
].map(datum => finalizeMetadatum(datum, { subobj: 'Practitioner' })).sort(sortMetadata);

const kClaimStates = [
  'UNSUBMITTED',
  'PENDING SUBMISSION',
  'PENDING SUBMISSION RESPONSE',
  'APPLIED',
  'SUBMISSION ALTERED',
  'PENDING CHANGE SUBMISSION',
  'PENDING REASSESSMENT SUBMISSION',
  'PENDING RESUBMISSION RESPONSE',
  'PENDING DELETION',
  'PENDING DELETION RESPONSE',
  'DELETED FROM AHS',
  'REJECTED',
];

// Utility functions
const padStart = (stringToPad, targetLength, padString) => {
  targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
  padString = String(padString || ' ');
  stringToPad = String(stringToPad);
  if (stringToPad.length > targetLength) {
    return String(stringToPad);
  } else {
    targetLength = targetLength - stringToPad.length;
    if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length);
    }
    return padString.slice(0, targetLength) + String(stringToPad);
  }
};

const mod10_tableA = [
  [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
];
const mod10_tableB = [
  0, 9, 8, 7, 6, 5, 4, 3, 2, 1,
];

const getMod10 = (code) => {
  let tableA_index = 0;
  let sum = 0;
  for (let i = code.length - 1; i >= 0; --i) {
    sum += parseInt(mod10_tableA[tableA_index][code[i]]);
    tableA_index = ((tableA_index + 1) % 2);
  }
  return mod10_tableB[sum % 10];
};

const getPriceFromClaim = (priceField) => {
  return (claim) => {
    if (claim[priceField]) {
      return intToPrice(parseInt(claim[priceField], 10));
    }
    return '';
  };
}

const getBooleanFromClaim = (booleanField) => {
  return (claim) => claim[booleanField] ? 'YES' : 'NO';
}

const remedyCIDtoAhsCID = (cid, created) => {
  const year = created ? created.substring(8) : '19';
  const sequence_number = padStart(cid, 7, '0');
  return 'GRA' + year + '01' + sequence_number + getMod10(sequence_number);
};

const isClaimRejected = (claim) => {
  return claim.state === 0 && (claim.rejected_claim || claim.submission_response);
};

const isClaimPartiallyPaid = (claim) => {
  return claim.state === 3 || claim.state === 4;
};

// Start metadata

const kClaimMetadata = [
  {
    title: 'Claim ID',
    short_title: 'CID',
  },
  {
    title: 'Claim Number (AHS)',
    short_title: 'CN AHS',
    fromClaim: claim => remedyCIDtoAhsCID(claim.claim_id, claim.createdAt)
  },
  { title: 'Claim ID Override' },
  { title: 'Remedy Patient ID' },
  { title: 'Remedy Practitioner ID' },
  { title: 'Skill Code' },
  { title: 'Business Arrangement', short_title: 'BA' },
  {
    title: 'Facility Number',
    short_title: 'Fac',
    name: 'facility_number',
  },
  {
    title: 'Functional Center',
    short_title: 'Func',
    name: 'functional_center',
  }, 
  { title: 'Location Code' },
  { title: 'Originating Facility' },
  { title: 'Pay To Code' },
  { title: 'Pay to ULI' },
  { title: 'Locum Arrangement Business Arrangement' },
  { title: 'Referral ID' },
  {
    title: 'Service Start Date',
    short_title: 'Service Date',
    type: 'date',
  },
  {
    title: 'Health Services Code',
    short_title: 'HSC',
  },
  {
    title: 'Calls',
    short_title: 'Calls',
  },
  {
    title: 'Encounter Number',
    short_title: 'Enc',
  },
  {
    title: 'Hospital Admission Date',
    type: 'date',
  },
  { title: 'Good Faith' },
  { title: 'Chart Number' },
  { title: 'Intercept Reason' },
  { title: 'Confidential Indicator' },
  { title: 'Newborn Code' },
  { title: 'EMSAF Indicator' },
  { title: 'Paper Supporting Document Indicator' },
  { title: 'Tooth Code' },
  {
    title: 'Tooth Surfaces',
    fromClaim: claim => [
      claim.tooth_surface_1,
      claim.tooth_surface_2,
      claim.tooth_surface_3,
      claim.tooth_surface_4,
      claim.tooth_surface_5,
      claim.tooth_surface_6,
    ].filter(tooth => tooth).join(', ')
  },
  { title: 'Supporting Text' },
  {
    title: 'Internal Notes',
    name: 'notes'
  },
  { title: 'Submission Response' },
  {
    title: 'Diagnosis Codes',
    short_title: 'Diag',
    fromClaim: claim => [
        claim.diagnosis_code_1,
        claim.diagnosis_code_2,
        claim.diagnosis_code_3,
      ].filter(diag => diag).join(', '),
  },
  {
    title: 'Explicit Fee Modifiers',
    short_title: 'Mods',
    fromClaim: claim => [
        claim.explicit_fee_modifier_1,
        claim.explicit_fee_modifier_2,
        claim.explicit_fee_modifier_3,
      ].filter(mod => mod).join(', '),
  },
  {
    title: 'Owner User Id',
    short_title: 'RemUID',
  },
  {
    title: 'Rejected Claim',
    short_title: 'Rejected CID',
  },
  {
    title: 'State',
    short_title: 'State',
    tooltip: claim => {
      if (isClaimRejected(claim)) {
        return denseExp[claim.submission_response].desc;
      }
      if (isClaimPartiallyPaid(claim) && claim.submission_response) {
        return denseExp[claim.submission_response].desc;
      }
      return '';
    },
    fromClaim: claim => {
      if (isClaimRejected(claim)) {
        return "REJECTED: " + claim.submission_response;
      }
      if (isClaimPartiallyPaid(claim) && claim.submission_response) {
        return kClaimStates[claim.state] + ": " + claim.submission_response;
      }
      return kClaimStates[claim.state];
    },
  },
  {
    title: 'Balance Owing',
    short_title: 'Balance',
    numeric: true,
    fromClaim: (claim) => intToPrice(claim.balance_owing),
  },
  {
    title: 'Claimed Amount',
    short_title: 'Claimed',
    numeric: true,
    fromClaim: getPriceFromClaim('claimed_amount'),
  },
  {
    title: 'Claimed Amount Indicator',
    short_title: 'ClaimedInd',
    fromClaim: getBooleanFromClaim('claimed_amount_indicator')
  },
  {
    title: 'Applied Payment',
    short_title: 'Applied',
    numeric: true,
    fromClaim: getPriceFromClaim('applied_payment'),
  },
  {
    title: 'Created',
    name: 'createdAt',
    type: 'date',
  },
  {
    title: 'Last Updated',
    name: 'updatedAt',
    type: 'date',
  },
  {
    title: 'Submission Date',
    name: 'submission_date',
    type: 'date',
  },

].map(datum => finalizeMetadatum(datum)).sort(sortMetadata);

const kClaimResponseMetadata = [
  {
    title: 'Submission Claim ID (AHS)',
    short_title: 'Sub CID',
    fromClaimResponse: (claim, index) => {
      let response = claim.ClaimResponses[index];
      let sequence_number = response.sequence_number.toString().padStart(7, '0');
      return response.submitter_prefix +
             response.current_year +
             response.source_code +
             sequence_number +
             response.check_digit;
    }
  },
  {
    title: 'Transaction Action Code',
    short_title: 'Act'
  },
  {
    title: 'Result',
    short_title: 'Res',
    fromClaimResponse: (claim, index) => {
      let response = claim.ClaimResponses[index];
      return [
        response.assessment_result_action_1,
        response.assessment_result_action_2,
      ].filter(result => result).join('');
    }
  },
  {
    title: 'Final Assessed Amount',
    short_title: 'Applied',
    fromClaimResponse: (claim, index) => {
      let payment = claim.ClaimResponses[index].final_assessed_amount;
      if (payment) {
        return intToPrice(parseInt(payment, 10));
      }
      return '';
    }
  },
  {
    title: 'Expected Payment Date',
    short_title: 'Pay Date',
    type: 'date',
  },
  {
    title: 'Assessment Date',
    short_title: 'Assess Date',
    type: 'date',
  },
  {
    title: 'Explanation Codes',
    short_title: 'Exp Codes',
    fromClaimResponse: (claim, index) => {
      let response = claim.ClaimResponses[index];
      return [
        response.explanation_code_1,
        response.explanation_code_2,
        response.explanation_code_3,
        response.explanation_code_4,
        response.explanation_code_5,
        response.explanation_code_6,
      ].filter(code => code).join(', ');
    },
  },
  {
    title: 'Fee Modifiers Used',
    short_title: 'Mods',
  }
].map(datum => finalizeMetadatum(datum, { subobj: 'ClaimResponses', multi: true }));

const kAllMetadata = [
  ...kClaimMetadata,
  ...kPatientMetadata,
  ...kPractitionerMetadata,
  ...kClaimResponseMetadata,
];

const kTableHeaderDefault = [
  'claim_id',
  'patient_name',
  'patient_health_number',
  'practitioner_name',
  'facility_number',
  'functional_center',
  'service_start_date',
  'health_services_code',
  'calls',
  'encounter_number',
  'diagnosis_codes',
  'explicit_fee_modifiers',
  'owner_user_id',
  'state',
  'applied_payment',
].map(name => {
  return kAllMetadata.find(metadata => metadata.name === name);
}).sort((a, b) => {
  return a.name > b.name ? 1 : -1;
});

const kPatientHistoryTableHeader = [
  'claim_id',
  'service_start_date',
  'health_services_code',
  'diagnosis_codes',
  'explicit_fee_modifiers',
  'state',
].map(name => {
  return kAllMetadata.find(metadata => metadata.name === name);
});

export {
  kPatientMetadata,
  kPractitionerMetadata,
  kClaimMetadata,
  kClaimResponseMetadata,
  kClaimStates,
  kAllMetadata,
  kTableHeaderDefault,
  kPatientHistoryTableHeader,
  getInputField,
  intToPrice,
  ymdToMdy
};
