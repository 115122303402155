import React, { Component } from "react";

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '@material-ui/icons/Close';

import ClaimForm from './ClaimForm.js';
import { ClaimFormProvider } from './ClaimFormProvider.js';
import ClaimTable from '../../Components/ClaimTable.js';
import {
  kTableHeaderDefault,
} from '../../Components/Common.js';

import { withData } from '../../Components/withData';

const styles = theme => ({
  root: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  paper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  claimItem: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  section: {
    position: 'relative',
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  estimate: {
    position: 'absolute',
    top: '32px',
    right: '32px',
  },
  newClaimButton: {
    margin: '8px',
  },
  newClaimButtonContainer: {
    position: 'absolute',
    bottom: '16px',
    right: '32px',
  },
  date: {
    width: '100%'
  },
});



class EnterClaim extends Component {
  constructor(props) {
    super(props);

    this.state = {
      claimToEdit: null,

      selectedClaims: [],

      focus: '',

      snackbar: {
        isOpen: false,
        message: ''
      },

      tableSettings: {
        order: 'desc',
        orderBy: 'claim_id',
        selected: [],
        page: 0,
        rowsPerPage: 5,
        columnData: kTableHeaderDefault,
      }
    };
  }

  openSnackbar = (message) => {
    this.setState({
      snackbar: {
        isOpen: true,
        message: message
      }
    })
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbar: {
        isOpen: false,
        message: ''
      }
    });
  }

  onSortingUpdate = (newTableSettings) => {
    this.setState((prevState) => {
      const tableSettings = prevState.tableSettings;
      Object.assign(tableSettings, newTableSettings);
      return { tableSettings };
    });
  }

  onTableSelect = (selected) => {
    this.setState({
      selectedClaims: selected
    });
  }

  onFocusChange = (newFocus) => {
    this.setState({
      focus: ''
    }, () => {
      this.setState({
        focus: newFocus
      });
    });
  }

  onEditRecent = (claimToEdit) => {
    this.setState({
      claimToEdit
    });
  }

  onCancel = () => {
    this.setState({
      claimToEdit: null
    }, () => {
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    });
  }

  onSave = () => {
    this.setState({
      claimToEdit: null
    }, () => {
      if (this.props.onSave) {
        this.props.onSave();
      }
    });
  }

  render() {
    let classes = this.props.classes;

    return (
      <React.Fragment>
        <ClaimFormProvider
          claim={this.state.claimToEdit ? this.state.claimToEdit : this.props.claim}
          claimEndpoint={this.props.claimEndpoint}
          onSave={this.onSave}
          onCancel={this.onCancel}
          onFocusChange={this.onFocusChange}
          onError={this.openSnackbar}
        >
          <ClaimForm
            focus={this.state.focus}
            onError={this.openSnackbar}
            isEdit={this.props.claim || this.state.claimToEdit}
          />
          { !this.props.onSave && (
            <Grid container className={classes.root}>
              <Grid item md={12} xs={12} className={classes.section}>
                <ClaimTable
                  title='Recently Entered Claims'
                  data={this.props.claimEndpoint.newData}
                  tableSettings={this.state.tableSettings}
                  onSortingUpdate={this.onSortingUpdate}
                  onSelect={this.onTableSelect}
                  onEditClaim={this.onEditRecent}
                />
              </Grid>
            </Grid>
          )}
        </ClaimFormProvider>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbar.isOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackbarClose}
          message={this.state.snackbar.message}
          action={(
            <IconButton
              aria-label='Close'
              color='inherit'
              onClick={this.handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        />
     </React.Fragment>
    );
  }
}

export default withStyles(styles)(withData(EnterClaim));
