import * as React from "react";
import axios from "axios";
import { parse } from "date-fns";

import hscData from "../../Data/hsc.json";
import feeData from "../../Data/fee.json";
import feeLookupData from "../../Data/feeLookup.json";
import diagData from "../../Data/diag.json";
import selfReferCodes from "../../Data/selfRefer.json";

import { kAllMetadata } from "../../Components/Common.js";

const fieldMappingFunc = (fields) => {
  return fields.map((field) => {
    if (!field.type) {
      field.type = "text";
    }

    if (!field.reset) {
      field.reset = "always";
    }

    if (!field.name) {
      field.name = field.title.toLowerCase().replace(/ /g, "_");
    }

    if (!field.isAdvanced) {
      field.isAdvanced = false;
    }

    return field;
  });
};

export const kClaimFields = [
  [
    {
      title: "Health Services Code",
      type: "autocomplete",
      dataOptions: { data: hscData },
    },
  ],
  [
    {
      title: "Service Start Date",
      type: "date",
      reset: "never",
    },
    { title: "Calls", initial: 1 },
    { title: "Encounter Number", initial: 1 },
  ],
  [
    {
      title: "Explicit Fee Modifiers",
      type: "autocomplete",
      dataOptions: {
        data: feeData,
        filter: (datum, claim) => {
          return (
            feeLookupData[claim.health_services_code] &&
            feeLookupData[claim.health_services_code].includes(datum)
          );
        },
        allowSuffix: true,
      },
      multiple: true,
    },
    {
      title: "Diagnosis Codes",
      type: "autocomplete",
      dataOptions: { data: diagData },
      multiple: true,
      reset: "patient",
    },
  ],
  [
    {
      title: "Referral ID",
      type: "autocomplete",
      dataOptions: {
        remoteApi: {
          get: "api/v1/get_referral_ids",
          add: "api/v1/add_referral_id",
          fields: [
            {
              title: "Name",
              name: "d",
              type: "text",
            },
            {
              title: "Referral ID",
              name: "c",
              type: "text",
            },
          ],
        },
        dataLength: 20,
      },
      reset: "patient",
    },
    { title: "Claim ID Override" },
    { title: "Supporting Text", multiline: true },
  ],
  [
    { title: "Service Provider PRID", disabled: true, reset: "practitioner" },
    { title: "Skill Code", reset: "practitioner" },
    { title: "Business Arrangement", reset: "practitioner" },
  ],
  [
    { title: "Facility Number", reset: "practitioner" },
    { title: "Functional Center", reset: "practitioner" },
    { title: "Location Code", reset: "practitioner" },
  ],
  [
    {
      title: "Hospital Admission Date",
      type: "date",
      cond: (claim) => claim.health_services_code === "03.03D",
      clearable: true,
      initial: null,
      reset: "patient",
    },
    {
      title: "Claimed Amount",
    },
    { title: "Internal Notes", name: "notes", multiline: true },
  ],
  [
    {
      title: "Confidential Indicator",
      type: "select",
      options: ["", "YES"],
      isAdvanced: true,
    },
    {
      title: "Newborn Code",
      type: "select",
      options: ["", "ADOP", "LVBR", "STBN", "MULT"],
      isAdvanced: true,
    },
    {
      title: "EMSAF Indicator",
      type: "select",
      options: ["", "YES"],
      isAdvanced: true,
    },
  ],
  [
    {
      title: "Pay To Code",
      type: "select",
      options: ["", "BAPY", "RECP", "OTHR", "CONT", "PRVD"],
      isAdvanced: true,
    },
    {
      title: "Pay To ULI",
      isAdvanced: true,
    },
  ],
].map(fieldMappingFunc);

const kResetPreferencesInit = kClaimFields
  .reduce((flattened, groupedFields) => {
    return [...flattened, ...groupedFields];
  }, [])
  .map((field) => {
    return {
      name: field.name,
      title: field.title,
      reset: field.reset,
      initial: field.initial,
      type: field.type,
    };
  });

const resetClaim = (currentClaim, resetData, resetType) => {
  let result = {};
  if (currentClaim.claim_id) {
    result.claim_id = currentClaim.claim_id;
  }

  resetData.forEach((field) => {
    if (resetType !== "init" && resetType !== field.reset) {
      result[field.name] = currentClaim[field.name];
    } else if (field.initial !== undefined) {
      result[field.name] = field.initial;
    } else {
      switch (field.type) {
        case "date":
          let date = new Date();
          date.setHours(0, 0, 0, 0); // we just need the date
          result[field.name] = date;
          break;
        default:
          result[field.name] = "";
          break;
      }
    }
  });
  return result;
};

function debounce(fn, delay) {
  var timer = null;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
}

export const ClaimFormContext = React.createContext();

export class ClaimFormProvider extends React.Component {
  constructor(props) {
    super(props);

    let claim = null;
    let patient = null;
    let practitioner = null;
    let referralPractitioner = null;
    let resetPreferences = kResetPreferencesInit;

    if (this.props.claim) {
      claim = this.createEditableClaim(this.props.claim);
      patient = claim.Patient;
      practitioner = claim.Practitioner;
      referralPractitioner = claim.ReferralPractitioner;
    } else {
      claim = resetClaim({}, resetPreferences, "init");
    }

    this.checkClaim = debounce(this.checkClaim, 300);

    this.state = {
      claim,
      patient,
      practitioner,
      referralPractitioner,

      estimated_price: 0,
      errors: [],
      patientErrors: [],
      practitionerErrors: [],
      referralPractitionerErrors: [],
      warnings: [],

      sendingClaim: false,

      resetPreferences,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claim !== this.props.claim && this.props.claim != null) {
      const claim = this.createEditableClaim(this.props.claim);
      this.setState(
        {
          claim: claim,
          patient: claim.Patient,
          practitioner: claim.Practitioner,
          referralPractitioner: claim.ReferralPractitioner,
        },
        () => {
          this.checkClaim(
            this.state.claim,
            this.state.patient,
            this.state.practitioner,
            this.state.referralPractitioner
          );
        }
      );
    }
  }

  onPatientChange = (patient) => {
    this.setState(
      (prevState) => {
        this.checkClaim(
          prevState.claim,
          patient,
          prevState.practitioner,
          prevState.referralPractitioner
        );

        const isEdit = this.props.claim != null;

        return {
          claim: isEdit
            ? prevState.claim
            : resetClaim(
                prevState.claim,
                this.state.resetPreferences,
                "patient"
              ),
          patient: patient,
        };
      },
      () => {
        this.props.onFocusChange("health_services_code");
      }
    );
  };

  onPractitionerChange = (practitioner) => {
    this.setState((prevState) => {
      let claim = prevState.claim;
      for (let key in practitioner) {
        if (key in claim) {
          claim[key] = practitioner[key] || "";
        }
      }

      this.checkClaim(
        claim,
        prevState.patient,
        practitioner,
        prevState.referralPractitioner
      );
      return {
        claim: claim,
        practitioner: practitioner,
      };
    });
  };

  onReferralPractitionerChange = (referralPractitioner) => {
    this.setState((prevState) => {
      this.checkClaim(
        prevState.claim,
        prevState.patient,
        prevState.practitioner,
        referralPractitioner
      );
      return {
        referralPractitioner: referralPractitioner,
      };
    });
  };

  constructOutgoingClaim = (
    claim,
    patient,
    practitioner,
    referralPractitioner
  ) => {
    let outClaim = {
      ...claim,
      diagnosis_code_1: this.state.claim.diagnosis_codes[0],
      diagnosis_code_2: this.state.claim.diagnosis_codes[1],
      diagnosis_code_3: this.state.claim.diagnosis_codes[2],
      explicit_fee_modifier_1: this.state.claim.explicit_fee_modifiers[0],
      explicit_fee_modifier_2: this.state.claim.explicit_fee_modifiers[1],
      explicit_fee_modifier_3: this.state.claim.explicit_fee_modifiers[2],
      diagnosis_codes: null,
      explicit_fee_modifiers: null,
    };
    if (patient) {
      outClaim.remedy_patient_id = patient.patient_id;
    }
    if (practitioner) {
      outClaim.remedy_practitioner_id = practitioner.practitioner_id;
    }
    if (referralPractitioner) {
      outClaim.remedy_referral_practitioner_id =
        referralPractitioner.referral_practitioner_id;
    }
    if (this.state.claim.claimed_amount) {
      if (
        typeof this.state.claim.claimed_amount === "string" &&
        this.state.claim.claimed_amount.match(/^[0-9]+\.[0-9][0-9]$/g)
      ) {
        outClaim.claimed_amount = this.state.claim.claimed_amount.replace(
          /\./g,
          ""
        );
        outClaim.claimed_amount_indicator = true;
      } else {
        // FIXME: Do this validation here, not on the server
        outClaim.claimed_amount = "INVALID";
      }
    } else {
      outClaim.claimed_amount = this.state.estimated_price;
    }

    return outClaim;
  };

  canSaveClaim = () => {
    return (
      this.state.patient !== null &&
      this.state.practitioner !== null &&
      this.state.errors.length === 0 &&
      !this.state.sendingClaim
    );
  };

  saveClaim = () => {
    let claim = this.constructOutgoingClaim(
      this.state.claim,
      this.state.patient,
      this.state.practitioner,
      this.state.referralPractitioner
    );

    this.setState(
      {
        sendingClaim: true,
      },
      () => {
        this.props.claimEndpoint
          .update(claim)
          .then(() => {
            this.setState(
              (prevState) => {
                delete prevState.claim.claim_id;
                return {
                  claim: resetClaim(
                    prevState.claim,
                    this.state.resetPreferences,
                    "always"
                  ),
                  sendingClaim: false,
                  errors: [
                    // FIXME: we currently put an empty error in to grey out
                    // the SAVE button
                    {
                      field: "__none__",
                      message: "Invalid",
                    },
                  ],
                  referralPractitioner: null,
                };
              },
              () => {
                this.props.onSave();
                this.props.onFocusChange("patient");
              }
            );
          })
          .catch((err) => {
            const err_msg = err.response
              ? err.response.data
              : "Communication error";
            this.props.onError(err_msg);
            this.setState({ sendingClaim: false });
          });
      }
    );
  };

  clearClaim = () => {
    this.setState({
      claim: resetClaim({}, this.state.resetPreferences, "init"),
      patient: null,
      practitioner: null,
      referralPractitioner: null,
    });
  };

  cancelEdit = () => {
    this.setState(
      {
        claim: resetClaim({}, this.state.resetPreferences, "init"),
      },
      () => {
        this.props.onCancel();
      }
    );
  };

  checkClaim = (claim, patient, practitioner, referralPractitioner) => {
    let checkedClaim = this.constructOutgoingClaim(
      claim,
      patient,
      practitioner,
      referralPractitioner
    );

    axios
      .post("api/v1/check_claim", checkedClaim)
      .then((results) => {
        this.setState((prevState) => {
          const errors = results.data.errors.reduce(
            (total, error) => {
              error.field = error.field.startsWith("diagnosis_code")
                ? "diagnosis_codes"
                : error.field.startsWith("explicit_fee_modifier")
                ? "explicit_fee_modifiers"
                : error.field;
              const metadata = kAllMetadata.find(
                (metadata) => error.field === metadata.name
              );
              if (metadata) {
                if (!metadata.subobj) {
                  total.claim.push(error);
                } else if (metadata.subobj === "Patient") {
                  total.patient.push(error);
                } else if (metadata.subobj === "Practitioner") {
                  total.practitioner.push(error);
                } else if (metadata.subobj === "ReferralPractitioner") {
                  total.referralPractitioner.push(error);
                }
              }
              return total;
            },
            {
              claim: [],
              patient: [],
              practitioner: [],
              referralPractitioner: [],
            }
          );
          return {
            errors: errors.claim,
            patientErrors: errors.patient,
            practitionerErrors: errors.practitioner,
            referralPractitionerErrors: errors.referralPractitioner,
            warnings: results.data.warnings,
            estimated_price: results.data.estimated_price,
          };
        });
      })
      .catch((err) => {});
  };

  createEditableClaim = (claim) => {
    let editableClaim = Object.assign({}, claim);
    editableClaim.Patient = Object.assign({}, claim.Patient);

    editableClaim.diagnosis_codes = [
      editableClaim.diagnosis_code_1,
      editableClaim.diagnosis_code_2,
      editableClaim.diagnosis_code_3,
    ].filter((code) => code);

    editableClaim.explicit_fee_modifiers = [
      editableClaim.explicit_fee_modifier_1,
      editableClaim.explicit_fee_modifier_2,
      editableClaim.explicit_fee_modifier_3,
    ].filter((code) => code);

    const fixupDate = (date, nullable) => {
      return date
        ? parse(date, "MM-dd-yyyy", new Date())
        : nullable
        ? null
        : new Date();
    };

    editableClaim.service_start_date = fixupDate(
      editableClaim.service_start_date,
      false
    );
    editableClaim.hospital_admission_date = fixupDate(
      editableClaim.hospital_admission_date,
      true
    );
    editableClaim.Patient.birthdate = fixupDate(
      editableClaim.Patient.birthdate,
      true
    );

    // if the claim is "Applied", change it to "Submission Altered"
    if (editableClaim.state === 3) {
      editableClaim.state = 4;
    }

    if (!editableClaim.claimed_amount_indicator) {
      editableClaim.claimed_amount = "";
    }

    return editableClaim;
  };

  onChange = (value, name, inputCallback) => {
    const state_callback = (prevState) => {
      let newData = prevState.claim;
      newData[name] = value;

      this.checkClaim(
        newData,
        prevState.patient,
        prevState.practitioner,
        prevState.referralPractitioner
      );

      return { claim: newData };
    };

    if (name === "patient") {
      return this.onPatientChange(value);
    }

    if (name === "practitioner") {
      return this.onPractitionerChange(value);
    }

    if (name === "referralPractitioner") {
      return this.onReferralPractitionerChange(value);
    }

    if (name === "health_services_code") {
      this.setState(
        (prevState) => {
          let newData = prevState.claim;
          const prevCodeSelfReferred = selfReferCodes.includes(
            newData.health_services_code
          );
          if (newData.referral_id === "" || prevCodeSelfReferred) {
            newData.referral_id =
              selfReferCodes.includes(value) && prevState.practitioner !== null
                ? prevState.practitioner.service_provider_prid
                : "";
          }

          return { claim: newData };
        },
        () => {
          this.setState(state_callback, inputCallback);
        }
      );
    } else {
      this.setState(state_callback, inputCallback);
    }
  };

  onResetPreferencesChange = (resetPreferences) => {
    this.setState({
      resetPreferences,
    });
  };

  render() {
    return (
      <ClaimFormContext.Provider
        value={{
          claim: this.state.claim,
          patient: this.state.patient,
          practitioner: this.state.practitioner,
          referralPractitioner: this.state.referralPractitioner,
          errors: this.state.errors,
          patientErrors: this.state.patientErrors,
          practitionerErrors: this.state.practitionerErrors,
          referralPractitionerErrors: this.state.referralPractitionerErrors,
          resetPreferences: this.state.resetPreferences,
          onResetPreferencesChange: this.onResetPreferencesChange,
          onChange: this.onChange,
          saveClaim: this.saveClaim,
          clearClaim: this.clearClaim,
          cancelEdit: this.cancelEdit,
          canSaveClaim: this.canSaveClaim(),
          sendingClaim: this.state.sendingClaim,
          estimatedPrice: this.state.estimated_price,
        }}
      >
        {this.props.children}
      </ClaimFormContext.Provider>
    );
  }
}
