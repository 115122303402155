import React, { Component } from 'react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
  root: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '36px',
  },
  paper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  section: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  userData: {
    paddingBottom: '8px',
  },
  textField: {
    paddingBottom: '8px',
  },
  img: {
    width: '100%',
  }
});

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: '',
      username: '',
      email: '',
      is_super_user: false,

      userList: [],

      showPassword: false,

      newPass: {
        user: '',
        password: '',
        confirm: ''
      },

      totp_token: '',
      two_factor_enabled: false,
      two_factor_qrcode: null,

      snackbar: {
        isOpen: false,
        message: ''
      },

      newUser: {
        username: '',
        password: '',
        email: '',
        is_super_user: false,
      }
    };
  }

  componentWillMount() {
    axios.post('/api/v1/user_info',
      {}
    ).then((response) => {
      this.setState((prevState) => {
        const newPass = prevState.newPass;
        newPass.user = response.data.user_id;

        if (response.data.is_super_user) {
          axios.post('/api/v1/get_all_users',
            {}
          ).then((response) => {
            this.setState({
              userList: response.data
            });
          });
        }

        return {
          username: response.data.username,
          user_id: response.data.user_id,
          email: response.data.email,
          two_factor_enabled: response.data.two_factor_enabled,
          is_super_user: response.data.is_super_user,
          newPass
        };
      });
    });
  }

  openSnackbar = (message) => {
    this.setState({
      snackbar: {
        isOpen: true,
        message: message
      }
    });
  }

  handleSnackbarClose = () => {
    this.setState({
      snackbar: {
        isOpen: false,
        message: ''
      }
    });
  }

  request2fa = () => {
    axios.post('/api/v1/get_2fa_image',
      {}
    ).then((response) => {
      this.setState({
        two_factor_qrcode: response.data.qrcode
      });
    }).catch((err) => {
    });
  }

  get2faChange = (url, enabled) => {
    return () => {
      axios.post(url,
        { totp_token: this.state.totp_token }
      ).then((response) => {
        this.setState({
          two_factor_enabled: enabled,
          two_factor_qrcode: null,
          totp_token: '',
        });
      }).catch((err) => {
        this.setState({
          totp_token: '',
        });
      });
    }
  }

  disable2fa = this.get2faChange('/api/v1/disable_2fa', false);
  enable2fa = this.get2faChange('/api/v1/enable_2fa', true);

  getFieldChange = (subobj, field, isCheckbox) => {
    return (event) => {
      const cached = event.target.value;
      this.setState((prevState) => {
        const newObj = prevState[subobj];
        if (isCheckbox) {
          newObj[field] = !newObj[field];
        } else {
          newObj[field] = cached;
        }
        return { [subobj]: newObj };
      });
    };
  }

  changePassword = (e) => {
    e.preventDefault();

    const { newPass } = this.state;

    if (newPass.password !== newPass.confirm) {
      return;
    }
    axios.post('/api/v1/change_password', {
      password: this.state.newPass.password,
      user_id: this.state.newPass.user
    }).then((response) => {
      this.openSnackbar('Password updated successfully');
      this.setState({
        newPass: {
          user: this.state.user_id,
          password: '',
          confirm: ''
        }
      });
    }).catch((err) => {
      this.openSnackbar(err.response.data);
    });
  }

  addNewUser = (e) => {
    e.preventDefault();

    const { newUser } = this.state;

    axios.post('/api/v1/add_user',
      newUser
    ).then((response) => {
      this.openSnackbar('User Added Successfully');
      this.setState((prevState) => {
        const { userList } = prevState;
        newUser.user_id = response.data.user_id;
        userList.push(newUser);

        return {
          userList: userList,
          newUser: {
            username: '',
            email: '',
            password: '',
            is_super_user: false
          }
        };
      });
    }).catch((err) => {
      this.openSnackbar(err.response.data);
    });
  }

  render() {
    const { classes } = this.props;
    const {
      username,
      user_id,
      email,
      is_super_user,
      two_factor_enabled,
      two_factor_qrcode,
      totp_token,
      newPass,
      userList,
      newUser
    } = this.state;

    return (
      <Grid container className={classes.root}>
        <Grid item md={2} xs={12} className={classes.section}>
          <Grid item md={12} xs={12} className={classes.section}>
            <Paper className={classes.paper}>
              <Typography variant='h6'>
                Username
              </Typography>
              <Typography variant='subtitle1' className={classes.userData}>
                {username}
              </Typography>
              <Typography variant='h6'>
                ID
              </Typography>
              <Typography variant='subtitle1' className={classes.userData}>
                {user_id}
              </Typography>
              <Typography variant='h6'>
                Email
              </Typography>
              <Typography variant='subtitle1' className={classes.userData}>
                {email}
              </Typography>
              <Typography variant='h6'>
                Type
              </Typography>
              <Typography variant='subtitle1' className={classes.userData}>
                {is_super_user ? 'Admin' : 'Standard'}
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={12} xs={12} className={classes.section}>
            <Paper className={classes.paper}>
              <Typography variant='h6' align='center'>
                Change Password
              </Typography>
              <form onSubmit={this.changePassword}>
                <Grid container>
                  { is_super_user && 
                    <Select
                      native
                      input={<Input fullWidth />}
                      value={newPass.user}
                      onChange={this.getFieldChange('newPass', 'user')}
                    >
                      {userList.map((user, index) => {
                        return (
                          <option key={index} value={user.user_id}>
                            {user.username}
                          </option>
                        );
                      })}
                    </Select>
                  }
                  <TextField
                    label="New Password"
                    type="password"
                    value={newPass.password}
                    fullWidth
                    onChange={this.getFieldChange('newPass', 'password')}
                  />
                </Grid>
                <Grid container>
                  <TextField
                    label="Confirm New Password"
                    type="password"
                    value={newPass.confirm}
                    error={newPass.confirm !== newPass.password}
                    fullWidth
                    onChange={this.getFieldChange('newPass', 'confirm')}
                  />
                </Grid>
                <Button fullWidth type='submit'>
                  Submit
                </Button>
              </form>
              <div className={classes.section} />
              <Typography variant='h6' align='center'>
                { two_factor_enabled ? 
                    'Disable Two Factor' :
                    'Enable Two Factor'
                }
              </Typography>
              { two_factor_qrcode && (
                <img className={classes.img} src={two_factor_qrcode} alt='' />
              )}
              { (two_factor_enabled || two_factor_qrcode) && (
                <TextField
                  className={classes.textField}
                  label="Two Factor Code"
                  type="password"
                  value={totp_token}
                  fullWidth
                  onChange={(e) => this.setState({totp_token: e.target.value})}
                />
              )}
              <Button
                onClick={two_factor_enabled ?
                           this.disable2fa : (
                             two_factor_qrcode === null ?
                               this.request2fa :
                               this.enable2fa
                           )
                }
                fullWidth
              >
                { two_factor_enabled ? 
                    'Disable 2FA' :
                    'Enable 2FA'
                }
              </Button>
            </Paper>
          </Grid>
        </Grid>
        { is_super_user && (
          <Grid item md={8} xs={12} className={classes.section}>
            <Grid item md={12} xs={12} className={classes.section}>
              <Paper className={classes.paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>User ID</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { userList.map(user => {
                        return (
                          <TableRow key={user.user_id}>
                            <TableCell>{user.user_id}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.is_super_user ? 'Admin' : 'Standard'}</TableCell>
                          </TableRow>
                        );
                      })
                    }
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        )}
        { is_super_user && (
          <Grid item md={2} xs={12} className={classes.section}>
            <Grid item md={12} xs={12} className={classes.section}>
              <Paper className={classes.paper}>
                <Typography variant='h6'>
                  Add New User
                </Typography>
                <form onSubmit={this.addNewUser}>
                  <TextField
                    label="Username"
                    fullWidth
                    value={newUser.username}
                    onChange={this.getFieldChange('newUser', 'username')}
                  />
                  <TextField
                    label="Email"
                    fullWidth
                    value={newUser.email}
                    onChange={this.getFieldChange('newUser', 'email')}
                  />
                  <TextField
                    label="Password"
                    fullWidth
                    value={newUser.password}
                    onChange={this.getFieldChange('newUser', 'password')}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newUser.is_super_user}
                        onChange={this.getFieldChange('newUser', 'is_super_user', true)}
                      />
                    }
                    label='Admin'
                  />
                  <Button type='submit' fullWidth>
                    Submit
                  </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbar.isOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackbarClose}
          message={this.state.snackbar.message}
          action={(
            <IconButton
              aria-label='Close'
              color='inherit'
              className={classes.close}
              onClick={this.handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          )}
        />
      </Grid>
    );
  }
}

export default withStyles(styles)(Users);
