import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';

import { DatePicker } from 'material-ui-pickers';

import { format, parse } from 'date-fns';

import DeleteIcon from '@material-ui/icons/Delete';

import {
  kClaimMetadata,
  kPatientMetadata,
  kPractitionerMetadata,
  kClaimResponseMetadata,
  kTableHeaderDefault,
  intToPrice
} from '../../Components/Common.js';

import ClaimTable from '../../Components/ClaimTable.js';
import EnterClaim from '../EnterClaim/EnterClaim.js';
import CollapsiblePaper from '../../Components/CollapsiblePaper.js';

import { withData } from '../../Components/withData';

const styles = theme => ({
  root: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '36px',
  },
  section: {
    position: 'relative',
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  filterTypography: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  button: {
    margin: '8px',
  },
  filterButtonContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  typographyHeader: {
    position: 'absolute',
    top: '112px',
  },
});

const kMetadata = {
  Claim : kClaimMetadata,
  Practitioner : kPractitionerMetadata,
  Patient : kPatientMetadata,
  ClaimResponses : kClaimResponseMetadata,
};

const kFilterKinds = [
  {
    id : 'inc',
    title: 'Contains',
    filter: (value, arg1) => String(value).includes(arg1)
  },
  {
    id : 'notinc',
    title: 'Does Not Contain',
    filter: (value, arg1) => !String(value).includes(arg1)
  },
  {
    id : 'eq',
    title: 'Equals',
    filter: (value, arg1) => value === arg1
  },
  {
    id : 'ne',
    title: 'Does Not Equal',
    filter: (value, arg1) => value !== arg1
  },
  {
    id : 'lt',
    title: 'Is Less Than',
    filter: (value, arg1) => value < arg1
  },
  {
    id : 'gt',
    title: 'Is Greater Than',
    filter: (value, arg1) => value > arg1
  },
  {
    id : 'btwn',
    title: 'Is Between',
    filter: (value, arg1, arg2) => value > arg1 && value < arg2
  },
].map((kind, index) => {
  return { ...kind, index: index };
});

const kStateIndex = kClaimMetadata.findIndex(meta => meta.name === 'state');
const kSubmissionResponseIndex = kClaimMetadata.findIndex(meta => meta.name === 'submission_response');
const kBalanceOwingIndex = kClaimMetadata.findIndex(meta => meta.name === 'balance_owing');

const kTabStateFilters = [
  // Prepare
  {
    any: [
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'UNSUBMITTED' },
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'SUBMISSION ALTERED' },
    ],
  },
  // Ready for Submission
  {
    any: [
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[2], arg1: 'PENDING SUBMISSION' },
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[2], arg1: 'PENDING CHANGE SUBMISSION' },
    ],
  },
  // Submitted
  {
    any: [
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'RESPONSE' },
    ],
    none: [
      { subobj: 'Claim', field: kSubmissionResponseIndex, kind: kFilterKinds[2], arg1: '63' },
    ],
  },
  // Partially Paid
  {
    any: [
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'APPLIED' },
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'SUBMISSION ALTERED' },
    ],
    every: [
      { subobj: 'Claim', field: kBalanceOwingIndex, kind: kFilterKinds[3], arg1: '0.00' },
    ]
  },
  // Refused
  {
    any: [
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'REJECTED' },
    ],
  },
  // Accepted
  {
    any: [
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'APPLIED' },
    ],
    every: [
      { subobj: 'Claim', field: kBalanceOwingIndex, kind: kFilterKinds[2], arg1: '0.00' },
    ]
  },
  // On Hold
  {
    any: [
      { subobj: 'Claim', field: kStateIndex, kind: kFilterKinds[0], arg1: 'RESPONSE' },
    ],
    every: [
      { subobj: 'Claim', field: kSubmissionResponseIndex, kind: kFilterKinds[2], arg1: '63' },
    ]
  },
  // All
  {
    allView : true,
  },
];

class ReviewClaims extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newFilter: { field: 0, kind: kFilterKinds[0], arg1: '', arg2: '', subobj: 'Claim' },
      filters: [],

      selectedClaims : [],

      total_paid : 0,

      tableSettings: {
        order: 'desc',
        orderBy: 'claim_id',
        selected: [],
        page: 0,
        rowsPerPage: 100,
        columnData: kTableHeaderDefault,
      },

      tabState: 7,

      filtersOpen: true,
      actionsOpen: true,
      tableColumnsOpen: false,

      deleteConfirmationOpen: false,
    };

    const getToggleOpen = (type) => {
      return () => {
        this.setState(prevState => {
          const result = {};
          result[type] = !prevState[type];
          return result;
        });
      };
    };

    this.toggleOpen = {
      filters: getToggleOpen('filtersOpen'),
      actions: getToggleOpen('actionsOpen'),
      tableColumns: getToggleOpen('tableColumnsOpen'),
    };
  }

  componentWillUnmount = () => {
  }

  getFilterChangeHandler = (field, isDate) => {
    return (e) => {
      let value = isDate ? e : e.target.value.toUpperCase();
      this.setState((prevState) => {
        let newFilter = prevState.newFilter;
        newFilter[field] = value;
        return { newFilter };
      });
    };
  }

  handleFilterFieldChange = (e) => {
    let values = e.target.value.split('.');
    let subobj = values[0];
    let field = values[1];
    this.setState((prevState) => {
      let newFilter = prevState.newFilter;
      newFilter.subobj = subobj;
      newFilter.field = field;
      if (kMetadata[subobj][field].type === 'date') {
        newFilter.arg1 = new Date();
        newFilter.arg2 = new Date();
      }
      return { newFilter };
    });
  }

  handleFilterKindChange = (e) => {
    let value = kFilterKinds[e.target.value];
    this.setState((prevState) => {
      let newFilter = prevState.newFilter;
      newFilter.kind = value;
      return { newFilter };
    });
  }

  addFilter = () => {
    this.setState((prevState) => {
      let filters = prevState.filters;
      filters.push(Object.assign({}, prevState.newFilter));
      let newFilter = prevState.newFilter;
      return { filters, newFilter };
    });
  }

  getFiltersFromMetadata = (metadata) => {
    return metadata.map((opt, index) => {
      let subobj = opt.subobj || 'Claim';
      return (
        <option value={subobj + '.' + index} key={index}>
          {opt.title}
        </option>
      );
    });
  }

  renderFilterArgField = (arg) => {
    const newFilter = this.state.newFilter;
    const type = kMetadata[newFilter.subobj][newFilter.field].type;
    if (type === 'date') {
      return (
        <DatePicker
          keyboard
          format='MM/dd/yyyy'
          disableOpenOnEnter
          mask={(value) => (value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : [])}
          animateYearScrolling={false}
          onChange={this.getFilterChangeHandler(arg, true)}
          value={this.state.newFilter[arg]}
        />
      );
    }
    return (
      <TextField
        fullWidth
        value={this.state.newFilter[arg]}
        onChange={this.getFilterChangeHandler(arg)}
      />
    );
  }

  renderFilterSelection = (classes) => {
    return (
      <Grid container spacing={40}>
        <Grid item xs={12} md={2}>
          <Select
            native
            fullWidth
            value={
              this.state.newFilter.subobj + '.' +
              this.state.newFilter.field
            }
            onChange={this.handleFilterFieldChange}
          >
            <optgroup label="Claim Info">
              { this.getFiltersFromMetadata(kClaimMetadata) }
            </optgroup>
            <optgroup label="Patient Info">
              { this.getFiltersFromMetadata(kPatientMetadata) }
            </optgroup>
            <optgroup label="Practitioner Info">
              { this.getFiltersFromMetadata(kPractitionerMetadata) }
            </optgroup>
            <optgroup label="Response Info">
              { this.getFiltersFromMetadata(kClaimResponseMetadata) }
            </optgroup>
          </Select>
        </Grid>
        <Grid item xs={12} md={1}>
          <Select
            native
            fullWidth
            value={this.state.newFilter.kind.index}
            onChange={this.handleFilterKindChange}
          >
            {kFilterKinds.map((opt, index) => {
              return (
                <option value={index} key={index}>
                  {opt.title}
                </option>
              );
            })}
          </Select>
        </Grid>
        <Grid item xs={12} md={2}>
          { this.renderFilterArgField('arg1') }
        </Grid>
        { this.state.newFilter.kind.id === 'btwn' && ([
          <Grid key={0} item xs={12} md={1} className={classes.filterTypography}>
            <Typography variant='body2'>
              And
            </Typography>
          </Grid>,
          <Grid key={1} item xs={12} md={2}>
            { this.renderFilterArgField('arg2') }
          </Grid>
        ])}
        <Grid item xs={12} md={1} className={classes.filterButtonContainer}>
          <Button onClick={this.addFilter}>
            Add Filter
          </Button>
        </Grid>
      </Grid>
    );
  }

  getRemoveFilter = (index) => {
    return () => {
      this.setState((prevState) => {
        prevState.filters.splice(index, 1);
        return { filters: prevState.filters };
      });
    };
  }

  onSelect = (selected) => {
    this.setState({
      selectedClaims: selected
    });
  }

  onEditClaimStart = (claim) => {
    this.setState({
      editClaim : claim
    });
  }

  onEditClaimCancel = () => {
    this.setState({
      editClaim : null
    });
  }

  onEditClaimSave = () => {
    this.setState({
      editClaim : null
    });
  }

  doClaimAction = (action) => {
    this.setState((prevState) => {
      const tableSettings = prevState.tableSettings;
      tableSettings.selected = [];
      return {
        tableSettings
      };
    }, () => {
      const claimIds = [...this.state.selectedClaims];
      const doActionRecursive = (index) => {
        this.props.claimEndpoint.action({
          claim_id: claimIds[index],
          action: action
        }, action);

        if (index < claimIds.length - 1) {
          doActionRecursive(index + 1);
        }
      };
      doActionRecursive(0);
    });
  }

  submitClaims = () => {
    this.doClaimAction('submit');
  }

  cancelClaims = () => {
    this.doClaimAction('cancel');
  }

  confirmDelete = () => {
    this.setState({
      deleteConfirmationOpen: true
    });
  }

  cancelDelete = () => {
    this.setState({
      deleteConfirmationOpen: false
    });
  }

  deleteClaims = () => {
    this.setState({
      deleteConfirmationOpen: false
    }, () => {
    this.doClaimAction('delete');
    });
  }

  reassessClaims = () => {
    this.doClaimAction('reassess');
  }

  acceptClaimBalances = () => {
    this.setState((prevState) => {
      const tableSettings = prevState.tableSettings;
      tableSettings.selected = [];
      return {
        tableSettings
      };
    }, () => {
      const claimIds = [...this.state.selectedClaims];
      const doActionRecursive = (index) => {
        this.props.claimEndpoint.action({
          claim_id: claimIds[index],
        }, 'accept_claim');

        if (index < claimIds.length - 1) {
          doActionRecursive(index + 1);
        }
      };
      doActionRecursive(0);
    });
  }

  renderFilters = (classes) => {
    return (
      <div>
      {this.state.filters.map((filter, index) => {
        let metadatum = kMetadata[filter.subobj][filter.field];
        let kind = kFilterKinds[filter.kind.index];
        let arg1 = filter.arg1;
        let arg2 = filter.arg2;
        if (metadatum.type === 'date') {
          arg1 = format(arg1, 'MM-dd-yyyy');
          arg2 = format(arg2, 'MM-dd-yyyy');
        }
        return (
          <Grid container key={index}>
            <Grid item xs={12} md={3} className={classes.filterTypography}>
              <Typography variant='body2'>
                { filter.subobj + ' ' +
                  metadatum.title + ' ' +
                  kind.title + ' ' +
                  arg1 +
                  (kind.id === 'btwn' ? ' and ' + arg2 : '')
                }
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <IconButton onClick={this.getRemoveFilter(index)}>
                < DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      </div>
    );
  }

  getFilteredClaims = () => {
    return this.props.claimEndpoint.data.filter(claim => {
      const filterFunc = (filter) => {
        let fieldMetadata = kMetadata[filter.subobj][filter.field];
        let data = fieldMetadata.fromClaim(claim);
        if (fieldMetadata.type === 'date') {
          data = parse(
            data,
            'MM-dd-yyyy',
            new Date()
          );
        }
        return kFilterKinds[filter.kind.index].filter(
          data, filter.arg1, filter.arg2
        );
      }
      const tabState = kTabStateFilters[this.state.tabState];
      return this.state.filters.every(filterFunc) && (
             tabState.allView || (
               (!tabState.any || tabState.any.some(filterFunc)) &&
               (!tabState.every || tabState.every.every(filterFunc)) &&
               !(tabState.none && tabState.none.some(filterFunc))
             ));
    });
  }

  onSortingUpdate = (newTableSettings) => {
    this.setState((prevState) => {
      const tableSettings = prevState.tableSettings;
      Object.assign(tableSettings, newTableSettings);
      return { tableSettings };
    });
  }

  handleTableColumnChange(field) {
    return () => {
      this.setState(prevState => {
        const tableSettings = prevState.tableSettings;
        const columnData = tableSettings.columnData;

        const index = columnData.indexOf(field);
        if (index !== -1) {
          columnData.splice(index, 1);

          if (tableSettings.orderBy === field.name) {
            tableSettings.orderBy = columnData[0].name;
          }
        } else {
          columnData.push(field);
          columnData.sort((a, b) => a.name > b.name ? 1 : -1);
        }

        return {
          tableSettings
        };
      });
    }
  }

  getTableColumnCheckbox = (field, index) => {
    return (
      <FormControlLabel key={index}
        control={
          <Checkbox
            checked={this.state.tableSettings.columnData.indexOf(field) !== -1}
            onChange={this.handleTableColumnChange(field)}
          />
        }
        label={field.title}
      />
    );
  }

  renderTableColumnToggles = () => {
    return (
      <Grid container>
        <Grid item md={12} xs={12} padding={8} />
        {
          [
            { metadata: kClaimMetadata, title: 'Claim' },
            { metadata: kPatientMetadata, title: 'Patient' },
            { metadata: kPractitionerMetadata, title: 'Practitioner' },
            { metadata: kClaimResponseMetadata, title: 'Response' },
          ].map((info, index) => {
            return (
              <Grid item md={3} xs={3} key={index}>
                <Typography variant='subtitle1'>
                  { info.title }
                </Typography>
                {
                  info.metadata.map((metadatum, index) =>
                    this.getTableColumnCheckbox(metadatum, index)
                  )
                }
              </Grid>
            );
          })
        }
      </Grid>
    );
  }

  renderTypographyHeader = (filtered_claims, classes) => {
    let total_paid = 0;
    let total_claimed = 0;
    let balance_owing = 0;
    filtered_claims.forEach(claim => {
      total_claimed += parseInt(claim.claimed_amount || 0, 10);
      total_paid += parseInt(claim.applied_payment || 0, 10);
      balance_owing += parseInt(claim.balance_owing || 0, 10);
    });

    return (
      <Grid container className={classes.typographyHeader}>
        <Grid item md={2} xs={2} />
        <Grid item md={2} xs={2}>
          <Typography variant='subtitle1' align='center'>
            { 'Number of Claims: ' + filtered_claims.length }
          </Typography>
        </Grid>
        <Grid item md={2} xs={2}>
          <Typography variant='subtitle1' align='center'>
            { 'Total Claimed: $' + intToPrice(total_claimed)}
          </Typography>
        </Grid>
        <Grid item md={2} xs={2}>
          <Typography variant='subtitle1' align='center'>
            { 'Total Paid: $' + intToPrice(total_paid)}
          </Typography>
        </Grid>
        <Grid item md={2} xs={2}>
          <Typography variant='subtitle1' align='center'>
            { 'Balance Owing: $' + intToPrice(balance_owing)}
          </Typography>
        </Grid>
        <Grid item md={2} xs={2} />
      </Grid>
    );
  }

  tabStateChange = (event, value) => {
    this.setState({
      tabState : value
    });
  }

  render() {
    const { classes } = this.props;

    let filtered_claims = this.getFilteredClaims();

    return this.state.editClaim ? (
      <EnterClaim
        claim={this.state.editClaim}
        onSave={this.onEditClaimSave}
        onCancel={this.onEditClaimCancel}
      />
    ) : (
      <div className={classes.root}>
        <Dialog open={this.state.deleteConfirmationOpen}>
          <DialogTitle>
            Confirm Delete
          </DialogTitle>
          <DialogContent>
            Are you sure you wish to delete the selected claims?
            If the claims have already been processed, this may trigger the revoking of any paid amounts.
          </DialogContent>
          <DialogActions>
            <Button onClick={this.deleteClaims}>
              Delete 
            </Button>
            <Button onClick={this.cancelDelete}>
              Cancel
            </Button>
          </DialogActions>

        </Dialog>
        <Grid container>
          <Grid item md={12} xs={12} className={classes.section}>
            <CollapsiblePaper
              title='Filters'
              open={this.state.filtersOpen}
              onToggle={this.toggleOpen['filters']}
            >
              {this.renderFilterSelection(classes)}
              {this.renderFilters(classes)}
            </CollapsiblePaper>
          </Grid>
          <Grid item md={12} xs={12} className={classes.section}>
            <CollapsiblePaper
              title='Table Columns'
              open={this.state.tableColumnsOpen}
              onToggle={this.toggleOpen['tableColumns']}
            >
              { this.renderTableColumnToggles() }
            </CollapsiblePaper>
          </Grid>
          <Grid item md={12} xs={12} className={classes.section}>
            <CollapsiblePaper
              title='Actions'
              open={this.state.actionsOpen}
              onToggle={this.toggleOpen['actions']}
            >
              <Grid item md={12} xs={12} className={classes.section}>
                {
                  [ { onClick: this.submitClaims,   text: 'Submit',   },
                    { onClick: this.cancelClaims,   text: 'Cancel',   },
                    { onClick: this.reassessClaims, text: 'Reassess', },
                    { onClick: this.confirmDelete,  text: 'Delete',   },
                    { onClick: this.acceptClaimBalances, text: 'Accept Balance',},
                  ].map((data, index) => {
                    return (
                      <Button
                        key={index}
                        variant='contained'
                        className={classes.button}
                        disabled={this.state.selectedClaims.length === 0}
                        onClick={data.onClick}
                      >
                        { data.text }
                      </Button>
                    );
                  })
                }
              </Grid>
            </CollapsiblePaper>
          </Grid>
          <Grid item md={12} xs={12} className={classes.section}>
            <Paper className={classes.paper}>
              <Tabs
                  centered={true}
                  value={this.state.tabState}
                  onChange={this.tabStateChange}
              >
                <Tab style={{ minWidth: '72px' }} label='Prepared' />
                <Tab style={{ minWidth: '72px' }} label='Ready for Submission' />
                <Tab style={{ minWidth: '72px' }} label='Submitted' />
                <Tab style={{ minWidth: '72px' }} label='Partially Paid' />
                <Tab style={{ minWidth: '72px' }} label='Refused' />
                <Tab style={{ minWidth: '72px' }} label='Accepted' />
                <Tab style={{ minWidth: '72px' }} label='On Hold' />
                <Tab style={{ minWidth: '72px' }} label='All' />
              </Tabs>
              { this.renderTypographyHeader(filtered_claims, classes) }
              <ClaimTable
                title='Claims'
                onSelect={this.onSelect}
                onEditClaim={this.onEditClaimStart}
                data={filtered_claims}
                tableSettings={this.state.tableSettings}
                onSortingUpdate={this.onSortingUpdate}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default withStyles(styles)(withData(ReviewClaims));

