import React, { Component } from "react";
import axios from "axios";

import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

import { getInputField } from "../../Components/RemedyInput";

import PractitionerFacilityDialog from "./PractitionerFacilityDialog";

const styles = (theme: any) => {
  return {
    editButton: {
      position: 'absolute' as 'absolute',
      right: '0',
      bottom: '-36px',
    }
  };
};

class PractitionerFacilitySelect extends Component<any, any> {
  public constructor(props: any) {
    super(props);

    this.state = {
      alternativeFacilities: null,
      selectedFacility: "",
      dialogOpen: false,
    };
  }

  public render() {
    return (
      <React.Fragment>
        {this.props.practitioner && this.state.alternativeFacilities !== null && (
          <React.Fragment>
            <PractitionerFacilityDialog
              key={this.props.practitioner.practitioner_id}
              open={this.state.dialogOpen}
              onClose={this.onDialogClose}
              onSave={this.onDialogSave}
              facilities={this.state.alternativeFacilities}
            />
            {getInputField(
              {
                type: "select",
                name: "alternativeFacilities",
                title: "Use Alternative Facility...",
                value: this.state.selectedFacility,
                onChange: this.onAlternativeFacilityChange,
                options: [
                  { title: "", value: "" },
                  ...this.state.alternativeFacilities.map(
                    (facility: any, index: number) => {
                      return {
                        title: `${facility.name}: ${facility.facility_number} ${facility.functional_center}`,
                        value: index,
                      };
                    }
                  ),
                ],
              },
              this.props.classes
            )}
            <Button
              className={this.props.classes.editButton}
              onClick={this.openEditDialog}
              variant="contained"
            >
              EDIT
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  public componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    if (prevProps.practitioner !== this.props.practitioner) {
      this.setState(
        {
          alternativeFacilities: null,
          selectedFacility: "",
        },
        () => {
          if (this.props.practitioner) {
            axios
              .post("api/v1/get_practitioner_facilities", {
                remedy_practitioner_id: this.props.practitioner.practitioner_id,
              })
              .then((result) => {
                this.setState(
                  {
                    alternativeFacilities: result.data.practitioner_facilities,
                  },
                  () => {}
                );
              });
          }
        }
      );
    }
  }

  private openEditDialog = () => {
    this.setState({
      dialogOpen: true,
    });
  };

  private onAlternativeFacilityChange = (value: any) => {
    this.setState(
      {
        selectedFacility: value,
      },
      () => {
        let info =
          value === "" || value < 0
            ? {
                facility_number: this.props.practitioner.facility_number,
                functional_center: this.props.practitioner.functional_center,
              }
            : {
                facility_number: this.state.alternativeFacilities[value]
                  .facility_number,
                functional_center: this.state.alternativeFacilities[value]
                  .functional_center,
              };
        this.props.updateClaim(info.facility_number, "facility_number", () => {
          this.props.updateClaim(
            info.functional_center,
            "functional_center",
            () => {}
          );
        });
      }
    );
  };

  private onDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  private onDialogSave = (facilities: any) => {
    axios
      .post("api/v1/update_practitioner_facilities", {
        remedy_practitioner_id: this.props.practitioner.practitioner_id,
        practitioner_facilities: facilities,
      })
      .then(() => {
        this.setState({
          alternativeFacilities: facilities,
          dialogOpen: false,
        });
      });
  };
}

export default withStyles(styles)(PractitionerFacilitySelect);
