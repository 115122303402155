import * as React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogTitle';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { withStyles } from '@material-ui/core/styles';

import { subDays } from 'date-fns';

import { kAllMetadata } from './Components/Common';
import { getInputField } from './Components/RemedyInput';

const styles = (theme: any) => {
  return {
    button: {
      position: 'absolute' as 'absolute'
    },
    closeButton: {
      position: 'absolute' as 'absolute',
      right: '32px',
      top: '32px',
    },
    date: {
      width: '100%',
    },
    section: {
      padding: '16px'
    }
  };
};

const kClaimField = kAllMetadata.find(m => m.name === 'claim_id');
const kCreatedField = kAllMetadata.find(m => m.name === 'createdAt');

class ClaimLoader extends React.Component<any, any> {
  public constructor(props: any) {
    super(props);

    this.state = {
      dialogOpen: false,
      from_claim_id: '0',
      from_createdAt: new Date(),
      to_claim_id: '0',
      to_createdAt: new Date(),
    };
  }

  public render() {
    const classes = this.props.classes;

    const fromClaimField = this.getField(kClaimField, 'From');
    const toClaimField = this.getField(kClaimField, 'To');

    const fromCreatedField = this.getField(kCreatedField, 'From');
    const toCreatedField = this.getField(kCreatedField, 'To');

    return (
      <React.Fragment>
        <Dialog
          open={this.state.dialogOpen}
          maxWidth='xl'
        >
          <DialogTitle>
            Load Claims
          </DialogTitle>
          <DialogContent>
            <IconButton onClick={this.onClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
            <Grid container={true} spacing={40} className={classes.section}>
              <Grid item={true} xs={12} md={6}>
                <Paper className={classes.section}>
                  <Grid container={true}>
                    <Grid item={true} xs={12} md={12}>
                      <Typography variant='h5'>
                        By Claim ID
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md={12}>
                      <Button
                        variant='outlined'
                        onClick={this.getClaimLoad(25000)}
                      >
                        Load Most Recent 25,000
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} md={12}>
                      <Button
                        variant='outlined'
                        onClick={this.getClaimLoad(0)}
                      >
                        Load All Claims
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} md={12}>
                      <Paper className={classes.section}>
                        <Grid container={true}>
                          <Grid item={true} xs={12} md={12}>
                            <Typography variant='h6'>
                              Custom
                            </Typography>
                          </Grid>
                          <Grid item={true} xs={12} md={5}>
                            {getInputField(fromClaimField, classes)}
                          </Grid>
                          <Grid item={true} xs={12} md={2} />
                          <Grid item={true} xs={12} md={5}>
                            {getInputField(toClaimField, classes)}
                          </Grid>
                          <Grid item={true} xs={12} md={12}>
                            <Button
                              variant='outlined'
                              onClick={this.onCustomClaimLoad}
                            >
                              Load Custom
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Paper className={classes.section}>
                  <Grid container={true}>
                    <Grid item={true} xs={12} md={12}>
                      <Typography variant='h5'>
                        By Created Date
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md={12}>
                      <Button
                        variant='outlined'
                        onClick={this.getCreatedLoad(15)}
                      >
                        Load Last 15 Days
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} md={12}>
                      <Button
                        variant='outlined'
                        onClick={this.getCreatedLoad(30)}
                      >
                        Load Last 30 Days
                      </Button>
                    </Grid>
                    <Grid item={true} xs={12} md={12}>
                      <Paper className={classes.section}>
                        <Grid container={true}>
                          <Grid item={true} xs={12} md={12}>
                            <Typography variant='h6'>
                              Custom
                            </Typography>
                          </Grid>
                          <Grid item={true} xs={12} md={5}>
                            {getInputField(fromCreatedField, classes)}
                          </Grid>
                          <Grid item={true} xs={12} md={2} />
                          <Grid item={true} xs={12} md={5}>
                            {getInputField(toCreatedField, classes)}
                          </Grid>
                          <Grid item={true} xs={12} md={12}>
                            <Button
                              variant='outlined'
                              onClick={this.onCustomCreatedLoad}
                            >
                              Load Custom
                            </Button>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <IconButton onClick={this.onClick} className={classes.button}>
          <CloudDownloadIcon />
        </IconButton>
      </React.Fragment>
    );
  }

  private getField(baseField: any, prefix: string) {
    const name = prefix.toLowerCase() + '_' + baseField.name;
    const title = prefix + ' ' + baseField.title;
    const onChange = (value: any) => {
      this.setState({
        [name]: value
      });
    };

    return {
      ...baseField,
      name,
      onChange,
      title,
      value: this.state[name],
    };
  }

  private onClick = () => {
    this.setState({
      dialogOpen: true
    });
  }

  private onClose = () => {
    this.setState({
      dialogOpen: false
    });
  }

  private getClaimLoad = (amount: number) => {
    return () => {
      this.props.load({
        maxPage: Math.floor(amount / 5000) - 1
      });
    }
  }

  private onCustomClaimLoad = () => {
    this.props.load({
      claim_id: {
        from: this.state.from_claim_id,
        to: this.state.to_claim_id,
      },
      maxPage: -1,
    });
  }

  private getCreatedLoad = (amount: number) => {
    return () => {
      this.props.load({
        createdAt: {
          from: subDays(new Date(), amount),
          to: new Date(),
        },
        maxPage: -1,
      });
    }
  }

  private onCustomCreatedLoad = () => {
    this.props.load({
      createdAt: {
        from: this.state.from_createdAt,
        to: this.state.to_createdAt,
      },
      maxPage: -1,
    });
  }
}

export default withStyles(styles)(ClaimLoader);
