import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import MinimizeIcon from '@material-ui/icons/ExpandLess';
import MaximizeIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  paper: {
    position: 'relative',
    paddingTop: '32px',
    paddingBottom: '32px',
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  button: {
    position: 'absolute',
    top: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  }
});

class CollapsiblePaper extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.paper}>
        <Typography variant='h5'>
          { this.props.title }
        </Typography>
        { this.props.open && this.props.children }
        <IconButton onClick={this.props.onToggle} className={classes.button}>
          { this.props.open ?
            (<MinimizeIcon className={classes.icon} />) :
            (<MaximizeIcon className={classes.icon} />)
          }
        </IconButton>
      </Paper>
    );
  }
}

export default withStyles(styles)(CollapsiblePaper);
