import * as React from 'react';

import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';

import Claim from '../Network/Claim';
import { kPatientHistoryTableHeader } from './Common';
import { withData } from './withData';

const styles = (theme: any) => {
  return {
    table: {}
  }
};

interface IProps {
  claimEndpoint: any;
  classes: any;
  onEdit: (claim: Claim) => {};
  patientEndpoint: any;
  practitionerEndpoint: any;
  patientId: number;
}

class PatientHistory extends React.Component<IProps, any> {
  public render() {
    const {
      classes
    } = this.props;

    const claims = this.getClaimsForPatient();

    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {kPatientHistoryTableHeader.map((metadatum, index) => {
              return (
                <TableCell key={index} padding='dense'>
                  { metadatum.short_title }
                </TableCell>
              );
            })}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          { claims.map((claim: Claim, index: number) => {
            return (
              <TableRow key={index}>
                {kPatientHistoryTableHeader.map((metadatum, cellIndex) => {
                  return (
                    <TableCell key={cellIndex} padding='dense'>
                      { metadatum.fromClaim(claim) }
                    </TableCell>
                  );
                })}
                <TableCell>
                  <IconButton onClick={this.getEditClaimClickHandler(claim)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  private getClaimsForPatient() {
    const {
      claimEndpoint,
      patientId
    } = this.props;

    return claimEndpoint.data.filter((claim: Claim) => 
      claim.remedy_patient_id === patientId
    ).sort((a: Claim, b: Claim) => {
      return a.claim_id > b.claim_id ? -1 : 1;
    });
  }

  private getEditClaimClickHandler(claim: Claim) {
    return () => {
      this.props.onEdit(claim);
    }
  }
}


export default withStyles(styles)(withData(PatientHistory));
