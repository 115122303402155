import * as React from 'react';

import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';

import ClaimTable from '../../Components/ClaimTable';

import Claim from '../../Network/Claim';

import { kTableHeaderDefault } from '../../Components/Common.js';

const styles = (theme: any) => ({
  paper: {
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '16px',
  },
  section: {
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '16px',
    position: 'relative' as 'relative',
  },
});

class ReviewTelinClaims extends React.Component<any, any> {
  public constructor(props: any) {
    super(props);

    this.state = {
      claims: [],

      query: {
        facility_number: '',
        from_service_date: '',
        patient_health_number: '',
        patient_surname: '',
        service_provider_prid: '',
        to_service_date: '',
      },

      tableSettings: {
        columnData: kTableHeaderDefault,
        order: 'desc',
        orderBy: 'claim_id',
        page: 0,
        rowsPerPage: 100,
        selected: [],
      },
    }
  }

  public render() {
    const { classes } = this.props;

    return (
      <Grid container={true}>
        <Grid item={true} md={12} className={classes.section}>
          <Paper className={classes.paper}>
            <Grid container={true}>
              { this.getTextField('Service Provider PRID') }
              { this.getTextField('Facility Number') }
              { this.getTextField('Patient Health Number') }
              { this.getTextField('Patient Surname') }
              { this.getTextField('From Service Date') }
              { this.getTextField('To Service Date') }
              <Grid item={true} md={12}>
                <Button onClick={this.onQuery}>
                  Query
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item={true} md={12} className={classes.section}>
          <Paper className={classes.paper}>
            { this.state.claims.length === 1000 && (
              <SnackbarContent
                message='Query Limit of 1000 reached'
              />)
            }
            <ClaimTable
              title='Telin Claims'
              data={this.state.claims}
              tableSettings={this.state.tableSettings}
              onSortingUpdate={this.onSortingUpdate}
              onEditClaim={this.onEditClaim}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  private onSortingUpdate = (newTableSettings: any) => {
    this.setState((prevState: any) => {
      const tableSettings = prevState.tableSettings;
      Object.assign(tableSettings, newTableSettings);
      return { tableSettings };
    });
  }

  private getOnChange = (fieldName: string) => {
    return (input: any) => {
      const value = input.target.value;

      this.setState((prevState: any) => {
        const query = prevState.query;
        query[fieldName] = value;
        return { query };
      });
    };
  }

  private getTextField = (name: string) => {
    const fieldName = name.toLowerCase().replace(/ /g, '_');

    return (
      <Grid item={true} md={2}>
        <TextField
          label={name}
          onChange={this.getOnChange(fieldName)}
          value={this.state.query[fieldName]}
        />
      </Grid>
    );
  }

  private onEditClaim = () => undefined

  private onQuery = () => {
    axios.post(
      'api/v1/find_telin_claims',
      this.state.query
    ).then((response: any) => {
      this.setState({
        claims: response.data.map((datum: any) => new Claim(datum))
      });
    });
  }
}

export default withStyles(styles)(ReviewTelinClaims);
