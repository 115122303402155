class ReferralPractitioner {
  public static findUrl = 'api/v1/find_referral_practitioners';
  public static idField = 'referral_practitioner_id';
  public static createUrl = 'api/v1/update_referral_practitioner';
  public static updateUrl = 'api/v1/update_referral_practitioner';
  public static checkUrl = 'api/v1/check_referral_practitioner';

  [x: string]: any;

  constructor(referralPractitionerData: any) {
    Object.assign(this, referralPractitionerData);
  }
}

export default ReferralPractitioner;
