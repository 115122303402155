import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";

import RemedyDialog from "../../Components/RemedyDialog";
import { withStyles } from "@material-ui/core/styles";

import { getInputField } from "../../Components/RemedyInput";

const kNumAltFacilities = 5;

const styles = (theme: any) => {
  return {
    facilityField: {
      width: "30%",
      padding: "4px",
    },
  };
};

class PractitionerFacilityField extends Component<any, any> {
  public render() {
    const {
      classes,
      index,
      name,
      facility_number,
      functional_center,
    } = this.props;

    return (
      <div>
        <TextField
          className={classes.facilityField}
          value={name}
          onChange={this.onNameChange}
          label={`Name ${index + 1}`}
        />
        <TextField
          className={classes.facilityField}
          value={facility_number}
          onChange={this.onFacilityChange}
          label={`Facility Number ${index + 1}`}
        />
        <TextField
          className={classes.facilityField}
          value={functional_center}
          onChange={this.onFunctionalChange}
          label={`Functional Center ${index + 1}`}
        />
      </div>
    );
  }

  private onNameChange = (event: any) => {
    this.props.onNameChange(event.target.value.toUpperCase(), this.props.index);
  };

  private onFacilityChange = (event: any) => {
    this.props.onFacilityChange(
      event.target.value.toUpperCase(),
      this.props.index
    );
  };

  private onFunctionalChange = (event: any) => {
    this.props.onFunctionalChange(
      event.target.value.toUpperCase(),
      this.props.index
    );
  };
}

const StyledPractitionerFacilityField = withStyles(styles)(
  PractitionerFacilityField
);

class PractitionerFacilityDialog extends Component<any, any> {
  public constructor(props: any) {
    super(props);

    this.state = {
      facilities: [],
    };
  }

  public componentDidMount() {
    const facilities = Array.from(Array(kNumAltFacilities));

    for (let i = 0; i < kNumAltFacilities; ++i) {
      if (i < this.props.facilities.length) {
        facilities[i] = {
          name: this.props.facilities[i].name,
          facility_number: this.props.facilities[i].facility_number,
          functional_center: this.props.facilities[i].functional_center,
        };
      } else {
        facilities[i] = {
          name: "",
          facility_number: "",
          functional_center: "",
        };
      }
    }

    this.setState({
      facilities,
    });
  }

  public render() {
    return (
      <RemedyDialog
        open={this.props.open}
        title="Edit Alternative Default Facilities"
        onSave={this.onSave}
        onClose={this.onClose}
      >
        {this.state.facilities.map((facility: any, index: number) => {
          return (
            <StyledPractitionerFacilityField
              key={index}
              name={facility.name}
              facility_number={facility.facility_number}
              functional_center={facility.functional_center}
              index={index}
              onNameChange={this.onNameChange}
              onFacilityChange={this.onFacilityChange}
              onFunctionalChange={this.onFunctionalChange}
            />
          );
        })}
      </RemedyDialog>
    );
  }

  private onNameChange = (value: string, index: number) => {
    this.setState((prevState: any) => {
      const { facilities } = prevState;
      facilities[index].name = value;
      return { facilities };
    });
  };

  private onFacilityChange = (value: string, index: number) => {
    this.setState((prevState: any) => {
      const { facilities } = prevState;
      facilities[index].facility_number = value;
      return { facilities };
    });
  };

  private onFunctionalChange = (value: string, index: number) => {
    this.setState((prevState: any) => {
      const { facilities } = prevState;
      facilities[index].functional_center = value;
      return { facilities };
    });
  };

  private onSave = () => {
    this.props.onSave(
      this.state.facilities.filter(
        (facility: any) =>
          facility.name !== "" &&
          facility.facility_number !== "" &&
          facility.functional_center !== ""
      )
    );
  };

  private onClose = () => {
    this.props.onClose();
  };
}

export default PractitionerFacilityDialog;
