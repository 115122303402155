import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  withStyles,
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { lighten } from '@material-ui/core/styles/colorManipulator';

import PatientHistory from './PatientHistory';

import {
  kPatientMetadata,
  kPractitionerMetadata,
  kClaimMetadata,
  kClaimResponseMetadata,
} from './Common.js';

import { withData } from './withData';

const getSorting = (columnData, order, orderBy) => {
  let { fromClaim, numeric } = columnData.find(datum => datum.name === orderBy);

  return (a,b) => {
    let a_datum = fromClaim(a) || '';
    let b_datum = fromClaim(b) || '';

    if (numeric) {
      a_datum = parseFloat(a_datum);
      b_datum = parseFloat(b_datum);
    }

    if (a_datum === b_datum) {
      return 0;
    }

    if (a_datum > b_datum) {
      return order === 'desc' ? -1 : 1;
    } else {
      return order === 'desc' ? 1 : -1;
    }

  };
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      simple,
      columnData
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          { !simple &&
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          }
          {columnData.map(column => {
            return (
              <TableCell
                key={column.name}
                align={column.numeric ? 'right' : 'left'}
                padding='dense'
                sortDirection={orderBy === column.name ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.name}
                    direction={order}
                    onClick={this.createSortHandler(column.name)}
                  >
                    {column.short_title}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingLeft: 0,
    paddingRight: theme.spacing.unit,
    minHeight: '32px',
  },
  highlight: {
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
  },
  title: {
    flex: '0 0 auto',
  },
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, title } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant='h5'>
            {title}
          </Typography>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const denseTableTheme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiTableCell: {
      root: {
        borderLeft: '1px solid rgba(244, 244, 244, 1)'
      },
      body: {
        fontSize: '0.7rem'
      },
      paddingDense: {
        paddingRight: '4px',
        paddingLeft: '4px',
      }
    }
  }
});


const styles = theme => ({
  root: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginTop: theme.spacing.unit * 1,
  },
  dialogSection: {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '16px',
  },
  table: {
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tooltip: {
    fontSize: 18
  },
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      dialogClaim: null,
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.props.tableSettings.orderBy === property &&
        this.props.tableSettings.order === 'desc') {
      order = 'asc';
    }

    this.props.onSortingUpdate({ order, orderBy });
  };

  selectData = (newSelected) => {
    if (this.props.onSelect) {
      this.props.onSelect(newSelected);
    }


    this.props.onSortingUpdate({ selected: newSelected });
  }

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.selectData(this.props.data.map((n, index) => n.claim_id));
      return;
    }
    this.selectData([]);
  };

  handleClick = (event, id, isCheckbox) => {
    if (this.props.simple) {
      return;
    }

    const { selected } = this.props.tableSettings;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (!isCheckbox && this.props.onEditClaim && selectedIndex !== -1) {
      const claim = this.props.data.find(claim => claim.claim_id === id);
     
      this.props.claimEndpoint.action({ claim_id: id }, 'history');

      this.setState({
        dialogOpen: true,
        dialogClaim: claim
      });
      return;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.selectData(newSelected);
  };

  handleChangePage = (event, page) => {
    this.props.onSortingUpdate({ page });
  };

  handleChangeRowsPerPage = event => {
    this.props.onSortingUpdate({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.props.tableSettings.selected.indexOf(id) !== -1;

  getDialogField = (metadata, index) => {
    return (
      <Typography key={index}>
        { metadata.title + ': ' +
          (
            metadata.fromClaim(this.state.dialogClaim) || ''
          )
        }
      </Typography>
    );
  }

  getDialogTableCell = (metadata, field_index, response_index) => {
    return (
      <TableCell key={field_index} padding='dense'>
        {
          metadata.fromClaim(this.state.dialogClaim, response_index)
        }
      </TableCell>
    );
  }

  onDialogCancel = () => {
    this.setState({
      dialogOpen: false
    });
  }

  onDialogEdit = () => {
    this.onEdit(this.state.dialogClaim);
  }

  onPatientHistoryEdit = (claim) => {
    this.onEdit(claim);
  }

  onEdit = (claim) => {
    this.setState({
      dialogOpen: false
    }, () => {
      this.props.onEditClaim(claim);
    });
  }

  getTableCellContent = (claim, column) => {
    const classes = this.props.classes;
    const content = column.fromClaim(claim) || '';
    const tooltip = column.tooltip ? column.tooltip(claim) : '';
    if (tooltip) {
      return (
        <Tooltip
          title={tooltip}
          classes={{
            tooltip: classes.tooltip
          }}
        >
          <div style={{color: 'blue', textDecoration: 'underline'}}>
            {content}
          </div>
        </Tooltip>
      );
    }
    return content;
  }

  render() {
    const {
      classes,
      data,
      title,
      simple,
      tableSettings,
    } = this.props;

    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      columnData
    } = tableSettings;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    let table_data = data.sort(getSorting(columnData, order, orderBy));
    if (!simple) {
      table_data = table_data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }

    return (
      <MuiThemeProvider theme={denseTableTheme}>
        { this.state.dialogClaim &&
          <Dialog
            open={this.state.dialogOpen}
            fullScreen
            maxWidth='md'
          >
            <DialogTitle>
              Claim Details
            </DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={4} className={classes.dialogSection}>
                  <Paper className={classes.dialogSection}>
                    <Typography variant='h5'>
                      Claim Information
                    </Typography>
                    {
                      kClaimMetadata.map((metadata, index) =>
                        this.getDialogField(metadata, index))
                    }
                  </Paper>
                </Grid>
                <Grid item xs={4} className={classes.dialogSection}>
                  <Paper className={classes.dialogSection}>
                    <Typography variant='h5'>
                      Patient Information
                    </Typography>
                    {
                      kPatientMetadata.map((metadata, index) =>
                        this.getDialogField(metadata, index))
                    }
                    <div className={classes.dialogSection} />
                    <Typography variant='h5'>
                      Patient History
                    </Typography>
                    <PatientHistory
                       patientId={this.state.dialogClaim.remedy_patient_id}
                       onEdit={this.onPatientHistoryEdit}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={4} className={classes.dialogSection}>
                  <Paper className={classes.dialogSection}>
                    <Typography variant='h5'>
                      Practitioner Information
                    </Typography>
                    {
                      kPractitionerMetadata.map((metadata, index) =>
                        this.getDialogField(metadata, index))
                    }
                  </Paper>
                  <div className={classes.dialogSection} />
                  <Paper className={classes.dialogSection}>
                    <Typography variant='h5'>
                      Submission History
                    </Typography>
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {
                            kClaimResponseMetadata.map((metadatum, index) => {
                              return (
                                <TableCell key={index} padding='dense'>
                                  {metadatum.short_title}
                                </TableCell>
                              );
                            })
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {
                        this.state.dialogClaim.ClaimResponses
                        .map((_, resp_index) => {
                            return (
                              <TableRow key={resp_index}>
                              {
                                kClaimResponseMetadata.map((metadata, index) =>
                                  this.getDialogTableCell(metadata, index, resp_index))
                              }
                              </TableRow>
                            );
                          })
                      }
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onDialogEdit}>
                Edit
              </Button>
              <Button onClick={this.onDialogCancel}>
                Cancel 
              </Button>
            </DialogActions>
          </Dialog>
        }
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            title={title}
            numSelected={selected.length}
          />
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                simple={simple}
                columnData={columnData}
              />
              <TableBody>
                {table_data
                  .map((n, index) => {
                    const isSelected = this.isSelected(n.claim_id);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isSelected}
                      >
                        { !simple &&
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected}
                              onClick={event => this.handleClick(event, n.claim_id, true)}
                            />
                          </TableCell>
                        }
                        {columnData.map((datum, index) => {
                          return (
                            <TableCell
                              padding='dense'
                              key={index}
                              align={datum.numeric ? 'right' : 'left'}
                              onClick={event => this.handleClick(event, n.claim_id, false)}
                            >
                              {this.getTableCellContent(n, datum)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          { !simple &&
            <TablePagination
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              page={page}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          }
        </Paper>
      </MuiThemeProvider>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withData(EnhancedTable));
