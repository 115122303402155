import React, { Component } from "react";
import PropTypes from "prop-types";
import keycode from 'keycode';
import Downshift from "downshift";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "relative"
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
  },
  inputRoot: {
    flexWrap: "wrap"
  }
});

function renderInput(inputProps) {
  const { InputProps, classes, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        classes: {
          root: classes.inputRoot
        },
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
  itemRender,
}) {
  const isHighlighted = highlightedIndex === index;
  // FIXME: Should we show selected?
  //const isSelected = (selectedItem !== null) ?
  //  itemKey(selectedItem) === itemKey(suggestion) :
  //  false;

  return (
    <MenuItem
      {...itemProps}
      selected={isHighlighted}
      component="div"
    >
      {itemRender(suggestion)}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
};

class RemedyAutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (this.props.multiple) {
      this.state.inputValue = '';
    };
  }

  onInputChange = (value, state) => {
    if (this.props.multiple) {
      this.setState({
        inputValue: value
      });
    } else {
      this.props.onSelect(value);

      if (this.props.defaultClear && value && state) {
        state.clearSelection();
      }
    }
  };

  onSelect = (item, state) => {
    this.onInputChange(this.props.itemToString(item));
  }

  onMultipleChange = (item, state) => {
    if (item) {
      let value = this.props.value;

      if (value.indexOf(item) === -1) {
        value = [...value, item];
      }
      this.setState({
        inputValue: '',
      });

      this.props.onSelect(value);
      if (state) {
        state.clearSelection();
      }
    }
  }

  onMultipleSelect = (item, state) => {
    this.onMultipleChange(this.props.itemToString(item));
  }


  onMultipleDelete = (item) => () => {
    let value = [...this.props.value];
    value.splice(value.indexOf(item), 1);

    this.props.onSelect(value);
  }
  
  addCurrentInput = (downshiftInput) => {
    const { inputValue } = this.state;
    const { multiple, itemToString, itemFilter, defaultClear } = this.props;
    const changeFunc = (item) => {
      if (multiple) {
        this.onMultipleChange(itemToString(item).toLowerCase());
      } else {
        this.onInputChange(
          defaultClear ? item : itemToString(item).toLowerCase()
        );
      }
    };

    const value = multiple ? inputValue : downshiftInput;

    if (value.length) {
      const filtered = itemFilter(value);

      if (filtered.length === 0) {
        if (this.props.onNewItemAdd) {
          return this.props.onNewItemAdd(value);
        }
      }

      const inputLower = value.toLowerCase();
      const newItem = filtered[0];
      const newItemString = itemToString(newItem).toLowerCase();

      if (inputLower.includes(newItemString)) {
        return changeFunc(newItem);
      } else {
        for (let i = 1; i < filtered.length; ++i) {
          const item = filtered[i];
          let itemString = itemToString(item).toLowerCase();
          if (itemString === inputLower) {
            return changeFunc(item);
          }
        }
      }

      if (this.props.onNewItemAdd) {
        return this.props.onNewItemAdd(value);
      }
    }
  }

  onMultipleKeyDown = (e) => {
    const { value } = this.props;
    const { inputValue } = this.state;
    if (value.length && !inputValue.length && keycode(e) === 'backspace') {
      const newValue = value.slice(0, value.length - 1);
      this.props.onSelect(newValue);
    }

    if (value.length === 3 && keycode(e) !== 'tab') {
      e.preventDefault();
      return;
    }
  }

//0: "unknown"
//1: "mouseUp"
//2: "itemMouseEnter"
//3: "keyDownArrowUp"
//4: "keyDownArrowDown"
//5: "keyDownEscape"
//6: "keyDownEnter"
//7: "clickItem"
//8: "blurInput"
//9: "changeInput"
//10: "keyDownSpaceButton"
//11: "clickButton"
//12: "blurButton"
//13: "controlledPropUpdatedSelectedItem"
//14: "touchStart"

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.mouseUp:
      case Downshift.stateChangeTypes.blurInput:
        if (this.props.multiple || this.props.defaultClear) {
          this.addCurrentInput(state.inputValue);
          return {
            ...changes,
            inputValue: '',
          };
        } else {
          return {
            ...changes,
            inputValue: state.inputValue,
          };
        }
      case Downshift.stateChangeTypes.keyDownEscape:
        if (this.props.multiple) {
          return {
            ...changes,
            inputValue: '',
          };
        }
        break;
      default:
        break;
    }
    return changes;
  }

  render() {
    const {
      classes,
      inputRef,
      itemFilter,
      itemToString,
      label,
      placeholder,
      itemRender,
      multiple,
      value,
      error,
      defaultClear,
      helperText
    } = this.props;

    let downshiftProps = {
      itemToString: itemToString,
      stateReducer: this.stateReducer
    };
    let localInputProps = {
      inputRef: inputRef,
      placeholder: placeholder,
    };

    let localInputLabelProps = {};

    if (multiple) {
      downshiftProps = {
        ...downshiftProps,
        onInputValueChange: this.onInputChange,
        onChange: this.onMultipleSelect,
        inputValue: this.state.inputValue
      };

      localInputProps = {
        ...localInputProps
      }

      if (this.state.inputValue.length || value.length) {
        localInputLabelProps.shrink = true;
      }

      if (value.length) {
        localInputProps.startAdornment = value.map(item => {
          return (
            <Chip
              key={item}
              tabIndex={-1}
              label={item}
              onDelete={this.onMultipleDelete(item)}
            />
          );
        });
      }
    } else {

      if (value != null) {
        downshiftProps = {
          ...downshiftProps,
          onInputValueChange: this.onInputChange,
          onChange: this.onSelect,
          inputValue: value,
        };
      } else {
        downshiftProps = {
          ...downshiftProps,
          onChange: this.onInputChange,
        };
      }
    }

    return (
      <div className={classes.root}>
        <Downshift
          {...downshiftProps}
        >
          {({
            getInputProps,
            getItemProps,
            isOpen,
            inputValue,
            selectedItem,
            highlightedIndex,
            clearSelection,
          }) => (
            <div className={classes.container}>
              {renderInput({
                inputRef: inputRef,
                fullWidth: true,
                classes,
                label: label,
                error: error,
                helperText: helperText,
                InputProps : getInputProps({
                  ...localInputProps,
                  onKeyDown: (e) => {
                    if (keycode(e) === 'enter') {
                      e.preventDefault();
                      this.addCurrentInput(inputValue);

                      if (defaultClear) {
                        clearSelection();
                      }
                    }

                    if (multiple) {
                      this.onMultipleKeyDown(e);
                    }
                  }
                }),
                InputLabelProps: {
                  ...localInputLabelProps
                }
              })}
              {isOpen ? (
                <Paper className={classes.paper} square>
                  {itemFilter(inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({
                        item: suggestion,
                        key: index
                      }),
                      highlightedIndex,
                      selectedItem,
                      itemRender,
                    })
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

RemedyAutoComplete.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RemedyAutoComplete);
