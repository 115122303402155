import React, { Component } from 'react';
import axios from 'axios';
import { withCookies } from 'react-cookie';

import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import { BrowserRouter as Router, Route, Link, withRouter } from 'react-router-dom';

import IdleTimer from 'react-idle-timer';

import DataDisplay from './Components/DataDisplay';
import DataProvider from './Network/DataProvider';
import EnterClaim from './Pages/EnterClaim/EnterClaim.js';
import ReviewClaims from './Pages/ReviewClaims/ReviewClaims.js';
import ReviewPage from './Pages/Other/ReviewPage';
import Ahs from './Pages/Other/Ahs.js';
import ReportPage from './Pages/Other/ReportPage';
import ReviewBatches from './Pages/Other/ReviewBatches.js';
import ReviewTelinClaims from './Pages/Other/ReviewTelinClaims';
import Login from './Pages/Other/Login.js';
import Users from './Pages/Other/Users.js';

import RemedyImage from './Assets/RemedyBilling.png';

const styles = theme => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#849de3',
      transition: 'all 0.3s ease',
    },
    fontFamily: 'Open Sans',
    fontSize: '13px',
    fontWeight: '400',
    letterSpacing: '2px',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  header: {
    color: 'white',
    backgroundColor: '#222',
    padding: '20px',
  },
  root: {
    flexGrow: 1,
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  toolbar: {
    backgroundColor: '#2e63e1',
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: 0,
    justifyContent: 'space-between',
  },
  remedyImage: {
    maxHeight: '50px',
    height: 'auto',
    width: 'auto',
  }
});

class ClaimSectionStub extends Component {
  renderEnterClaim = (props) => {
    return (
      <EnterClaim {...props} />
    );
  }

  renderReviewClaims = (props) => {
    return (
      <ReviewClaims
        onEnterPrint={this.props.hideAppBar}
        onExitPrint={this.props.showAppBar}
        {...props}
      />
    );
  }

  renderReviewNew = (props) => {
    return (
      <ReviewPage {...props} />
    );
  }

  render() {
    return (
      <div>
        <Route path="/claim" render={this.renderEnterClaim} />
        <Route path="/review" render={this.renderReviewClaims} />
        <Route path="/review_new" render={this.renderReviewNew} />
      </div>
    );
  }
}

const ClaimSection = withRouter(ClaimSectionStub);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuperUser: false,
      authenticated: false,
    };
  }

  onLogin = (is_super_user) => {
    this.setState({
      authenticated: true,
      isSuperUser: is_super_user
    });
  }
  
  onLogout = () => {
    axios.post('/api/v1/logout', {
    })
    .then((response) => {
      this.setState({
        authenticated: false,
        isSuperUser: false,
      });
    });
  }

  onIdle = () => {
    if (this.state.authenticated) {
      this.onLogout();
    }
  }

  componentWillMount = () => {
    axios.defaults.baseURL = '/';
    axios.defaults.headers.common['X-CSRF-Token'] =
      this.props.cookies.get('csrfToken') || '';
    axios.post('/api/v1/logged_in', {
    })
    .then((response) => {
      if (response.data.logged_in) {
        this.onLogin(response.data.is_super_user);
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <IdleTimer
           timeout={1000 * 60 * 10}
           onIdle={this.onIdle}
        />
        <Router>
          <div className="App">
            <div className={classes.root}>
              <AppBar position='static'>
                <Toolbar className={classes.toolbar}>
                  <img src={RemedyImage} className={classes.remedyImage} />
                  { this.state.authenticated ?
                    (<div>
                      <Link to="/account">
                        <Button className={classes.button} color='inherit'>Account</Button>
                      </Link>
                      <Link to="/claim">
                        <Button className={classes.button} color='inherit'>Enter Claim</Button>
                      </Link>
                      <Link to="/review">
                        <Button className={classes.button} color='inherit'>Review Claims</Button>
                      </Link>
                      { this.state.isSuperUser && (
                        <React.Fragment>
                          <Link to="/archive">
                            <Button className={classes.button} color='inherit'>Review Archived Claims</Button>
                          </Link>
                          <Link to="/report">
                            <Button className={classes.button} color='inherit'>Reports</Button>
                          </Link>
                        </React.Fragment>
                      )}
                      <Button className={classes.button} color='inherit' onClick={this.onLogout}>
                        Logout
                      </Button>
                    </div>) :
                    (<div>
                      <Link to="/login">
                        <Button className={classes.button} color='inherit'>Login</Button>
                      </Link>
                    </div>)}
                </Toolbar>
              </AppBar>
              { this.state.authenticated ? (
                  <DataProvider>
                    <DataDisplay>
                      { this.state.isSuperUser && (
                        <React.Fragment>
                          <Route path="/batches" render={props => (
                            <ReviewBatches />
                          )} />
                          <Route path="/archive" render={props => (
                            <ReviewTelinClaims />
                          )} />
                          <Route path="/ahs" render={props => (
                            <Ahs />
                          )} />
                          <Route path="/report" render={props => (
                            <ReportPage />
                          )} />
                        </React.Fragment>
                      )}
                      <Route path="/account" render={props => (
                        <Users />
                      )} />
                      <ClaimSection />
                    </DataDisplay>
                  </DataProvider>
                ) :
                (<div>
                  <Route path={["/", "/login"]} render={props => (
                    <Login onAuthenticate={this.onLogin} />
                  )} />
                </div>)}
            </div>
          </div>
        </Router>
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(withCookies(App));
