import * as React from "react";

import axios from "axios";
import FileDownload from "js-file-download";

import { HotKeys } from "react-hotkeys";

import { endOfWeek, startOfWeek } from "date-fns";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";

import { createMuiTheme, withStyles } from "@material-ui/core/styles";

import { kAllMetadata } from "../../Components/Common";

import { withData } from "../../Components/withData";

import Filter from "../../Components/Filter";
import WeekPicker from "../../Components/WeekPicker";

const styles = (theme: any) => ({
  paper: {
    paddingBottom: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
  },
  patient_name: {
    minWidth: "140px",
  },
  section: {
    paddingBottom: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
  },
  table: {
    paddingBottom: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
  },
  title: {
    paddingBottom: "32px",
  },
  summaryReportButton: {
    display: "flex",
  },
  warning: {
    display: "flex",
    padding: "8px",
    alignItems: "center",
    backgroundColor: "rgb(255, 244, 229)",
  },
});

class ReportPage extends React.Component<any, any> {
  private keyHandlers = {};

  public constructor(props: any) {
    super(props);

    this.state = {
      reportLoading: false,
      selectedDate: new Date(),
      selectedPractitioner: -1,
      snackbar: {
        isOpen: false,
        message: "",
      },
      fileFormat: "pdf",
    };
  }

  public componentWillMount() {
    const { practitionerEndpoint } = this.props;
    const practitioners = practitionerEndpoint.data.sort((a: any, b: any) => {
      return a.practitioner_name < b.practitioner_name ? -1 : 1;
    });
    this.setState({
      selectedPractitioner: practitioners[0].practitioner_id,
    });
  }

  public render() {
    const { classes } = this.props;

    const isCSV = this.state.fileFormat === "csv";

    return (
      <HotKeys handlers={this.keyHandlers} focused={true} attach={window}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.snackbar.isOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackbarClose}
          message={this.state.snackbar.message}
          action={
            <IconButton
              aria-label="Close"
              color="inherit"
              onClick={this.handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <Grid container={true}>
          <Grid item={true} md={12} className={classes.section}>
            <Paper className={classes.paper}>
              <FormControl>
                <FormLabel>File Format</FormLabel>
                <RadioGroup
                  row={true}
                  name="fileFormat"
                  value={this.state.fileFormat}
                  onChange={this.onFileFormatChange}
                >
                  <FormControlLabel
                    value="pdf"
                    control={<Radio />}
                    label="PDF"
                  />
                  <FormControlLabel
                    value="csv"
                    control={<Radio />}
                    label="CSV"
                  />
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grid>
          <Grid item={true} md={12} className={classes.section}>
            <Paper className={classes.paper}>
              <WeekPicker
                date={this.state.selectedDate}
                onWeekChange={this.onWeekChange}
              />
              <Button
                onClick={this.getOnGenerateReport(false)}
                disabled={this.state.reportLoading}
              >
                Generate Full Report
              </Button>
              <br />
              <div className={classes.summaryReportButton}>
                <Button
                  onClick={this.getOnGenerateReport(true)}
                  disabled={isCSV || this.state.reportLoading}
                >
                  Generate Summary Report
                </Button>
                {isCSV && (
                  <Paper className={classes.warning}>
                    <WarningIcon />
                    <div>Summary Reports are not available in CSV format</div>
                  </Paper>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item={true} md={12} className={classes.section}>
            <Paper className={classes.paper}>
              <Filter
                onApply={this.onGenerateFilteredReport}
                applyButtonText={"Generate Filtered Report"}
              />
            </Paper>
          </Grid>
        </Grid>
      </HotKeys>
    );
  }

  private onWeekChange = (date: any) => {
    this.setState({
      selectedDate: date,
    });
  };

  private onFileFormatChange = (event: any) => {
    this.setState({
      fileFormat: event.target.value,
    });
  };

  private getOnPractitionerChange() {
    return (event: any) => {
      this.setState({
        selectedPractitioner: parseInt(event.target.value, 10),
      });
    };
  }

  private getOnGenerateReport = (isSummary: boolean) => {
    return () => {
      axios
        .post(
          "/api/v1/get_report",
          {
            endDate: endOfWeek(this.state.selectedDate),
            fileFormat: this.state.fileFormat,
            isSummary,
            reportType: "weekly",
            startDate: startOfWeek(this.state.selectedDate),
          },
          {
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/json",
            },
            responseType: "blob",
          }
        )
        .then((response: any) => {
          FileDownload(response.data, "report." + this.state.fileFormat);
        })
        .catch((err: any) => {
          if (err.response.status === 503) {
            this.openSnackbar("Server is busy, please wait and try again");
          } else {
            this.openSnackbar("Error Generating Report");
          }
        });
    };
  };

  private onGenerateFilteredReport = (filters: any) => {
    axios
      .post(
        "/api/v1/get_filtered_report",
        {
          fileFormat: this.state.fileFormat,
          filters: filters,
        },
        {
          headers: {
            Accept: "application/pdf",
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      )
      .then((response: any) => {
        FileDownload(response.data, "report." + this.state.fileFormat);
      })
      .catch((err: any) => {
        if (err.response.status === 400) {
          this.openSnackbar("Filters are too broad");
        } else if (err.response.status === 503) {
          this.openSnackbar("Server is busy, please wait and try again");
        } else {
          this.openSnackbar("Error Generating Report");
        }
      });
  };

  private openSnackbar = (message: string) => {
    this.setState({
      snackbar: {
        isOpen: true,
        message: message,
      },
    });
  };

  private handleSnackbarClose = () => {
    this.setState({
      snackbar: {
        isOpen: false,
        message: "",
      },
    });
  };
}

export default withStyles(styles)(withData(ReportPage));
