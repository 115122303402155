import { ymdToMdy } from '../Components/Common';

class Patient {
  public static findUrl = 'api/v1/find_patients';
  public static idField = 'patient_id';
  public static createUrl = 'api/v1/update_patient';
  public static updateUrl = 'api/v1/update_patient';
  public static checkUrl = 'api/v1/check_patient';

  public birthdate?: string;

  [x: string]: any;

  constructor(patientData: any) {
    Object.assign(this, patientData);

    if (this.birthdate) {
      this.birthdate = ymdToMdy(this.birthdate);
    }
  }
}

export default Patient;
