import React, { Component } from "react";

import { ClaimFormContext } from "./ClaimFormProvider.js";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

import { withStyles } from "@material-ui/core/styles";

import RemedyDialog from "../../Components/RemedyDialog";

import { withData } from "../../Components/withData";

const styles = (theme: any) => {
  return {};
};

interface ResetOption {
  text: string;
  value: string;
}

const kResetOptions: ResetOption[] = [
  { text: "Always Reset", value: "always" },
  { text: "Never Reset", value: "never" },
  { text: "Reset When Patient Changes", value: "patient" },
  { text: "Reset When Practitioner Changes", value: "practitioner" },
];

class ResetPreferencesField extends Component<any, any> {
  public render() {
    const { title, reset } = this.props.field;
    return (
      <FormControl fullWidth={true} margin="dense">
        <InputLabel>{title}</InputLabel>
        <Select
          native={true}
          input={<Input fullWidth={true} />}
          value={reset}
          onChange={this.onChange}
        >
          {kResetOptions.map((option: ResetOption) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      </FormControl>
    );
  }

  private onChange = (event: any) => {
    this.props.onChange(this.props.field.name, event.target.value);
  };
}

class ResetPreferencesDialog extends Component<any, any> {
  public constructor(props: any) {
    super(props);

    this.state = {
      resetPreferences: [],
    };
  }

  public componentDidMount() {
    this.setState({
      resetPreferences: this.props.resetPreferences,
    });
  }

  public render() {
    return (
      <RemedyDialog
        open={this.props.open}
        title="Change Field Reset Preferences"
        onSave={this.onSave}
        onClose={this.props.onClose}
      >
        {this.state.resetPreferences.map((field: any) => {
          return (
            <ResetPreferencesField
              key={field.name}
              field={field}
              onChange={this.onFieldChange}
            />
          );
        })}
      </RemedyDialog>
    );
  }

  private onFieldChange = (fieldName: string, value: string) => {
    this.setState((prevState: any) => {
      const { resetPreferences } = prevState;
      resetPreferences.find(
        (field: any) => field.name === fieldName
      ).reset = value;

      return {
        resetPreferences,
      };
    });
  };

  private onSave = (cleanupCallback: any) => {
    cleanupCallback();
    this.props.onResetPreferencesChange(this.state.resetPreferences);
    this.props.onClose();
  };
}

class ResetPreferencesDialogWithContext extends Component {
  static contextType = ClaimFormContext;

  public render() {
    return (
      <ResetPreferencesDialog
        {...this.props}
        onResetPreferencesChange={this.context.onResetPreferencesChange}
        resetPreferences={this.context.resetPreferences}
      />
    );
  }
}

export default withStyles(styles)(withData(ResetPreferencesDialogWithContext));
