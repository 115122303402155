import React, { Component, PureComponent } from "react";
import { HotKeys } from "react-hotkeys";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { ClaimFormContext, kClaimFields } from "./ClaimFormProvider.js";
import PatientForm from "./PatientForm.js";
import PractitionerForm from "./PractitionerForm.js";
import ReferralPractitionerForm from "./ReferralPractitionerForm.js";
import { getInputField, intToPrice } from "../../Components/Common.js";
import ResetPreferencesDialog from "./ResetPreferencesDialog";

import { withData } from "../../Components/withData";

const styles = (theme) => ({
  root: {
    paddingTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  paper: {
    paddingTop: "16px",
    paddingBottom: "80px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  claimItem: {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  section: {
    position: "relative",
    paddingTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  estimate: {
    position: "absolute",
    top: "32px",
    right: "32px",
  },
  newClaimButton: {
    margin: "8px",
  },
  newClaimButtonContainer: {
    position: "absolute",
    bottom: "16px",
    right: "32px",
  },
  resetPreferenceOpenContainer: {
    position: "absolute",
    bottom: "16px",
    left: "32px",
  },
  date: {
    width: "100%",
  },
});

const fieldHotkeys = [
  {
    name: "health_services_code",
    hotkey: "ctrl+h",
  },
  {
    name: "diagnosis_codes",
    hotkey: "ctrl+d",
  },
  {
    name: "explicit_fee_modifiers",
    hotkey: "ctrl+f",
  },
  {
    name: "calls",
    hotkey: "ctrl+l",
  },
  {
    name: "encounter_number",
    hotkey: "ctrl+e",
  },
  {
    name: "facility_number",
    hotkey: "ctrl+a",
  },
  {
    name: "functional_center",
    hotkey: "ctrl+u",
  },
  {
    name: "referral_id",
    hotkey: "ctrl+i",
  },
  {
    name: "supporting_text",
    hotkey: "ctrl+g",
  },
];

class ClaimButton extends PureComponent {
  render() {
    return (
      <Button
        onClick={this.props.onClick}
        variant="contained"
        className={this.props.className}
        disabled={this.props.disabled}
      >
        {this.props.text}
      </Button>
    );
  }
}

class ClaimSaveButton extends Component {
  static contextType = ClaimFormContext;

  render() {
    return (
      <ClaimButton
        onClick={this.context.saveClaim}
        className={this.props.classes.newClaimButton}
        disabled={!this.context.canSaveClaim}
        text={this.context.sendingClaim ? "Saving..." : "Save"}
      />
    );
  }
}

class ClaimClearButton extends Component {
  static contextType = ClaimFormContext;

  render() {
    return (
      <ClaimButton
        onClick={this.context.clearClaim}
        className={this.props.classes.newClaimButton}
        disabled={false}
        text={"Clear"}
      />
    );
  }
}

class ClaimCancelButton extends Component {
  static contextType = ClaimFormContext;

  render() {
    return (
      <ClaimButton
        onClick={this.context.cancelEdit}
        className={this.props.classes.newClaimButton}
        disabled={false}
        text={"Cancel"}
      />
    );
  }
}

class ClaimResetPreferencesOpenButton extends Component {
  static contextType = ClaimFormContext;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  onClick = () => {
    this.setState({
      open: true,
    });
  };

  onClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <ResetPreferencesDialog open={this.state.open} onClose={this.onClose} />
        <ClaimButton
          onClick={this.onClick}
          className={this.props.classes.newClaimButton}
          disabled={false}
          text={"Change Reset Preferences"}
        />
      </React.Fragment>
    );
  }
}

class ClaimForm extends PureComponent {
  constructor(props) {
    super(props);

    this.patientInput = null;
    this.setPatientInputRef = (e) => {
      this.patientInput = e;
    };

    this.practitionerInput = null;
    this.setPractitionerInputRef = (e) => {
      this.practitionerInput = e;
    };

    this.referralPractitionerInput = null;
    this.setReferralPractitionerInputRef = (e) => {
      this.referralPractitionerInput = e;
    };

    this.keyMap = {
      goToPatient: "ctrl+p",
      goToPractitioner: "ctrl+r",
      saveClaim: "ctrl+s",
    };

    this.keyHandlers = {
      goToPatient: (e) => {
        e.preventDefault();
        this.patientInput.focus();
      },
      goToPractitioner: (e) => {
        e.preventDefault();
        this.practitionerInput.focus();
      },
      saveClaim: (e) => {
        e.preventDefault();
        if (document.activeElement.blur) {
          document.activeElement.blur();
        }
        setTimeout(() => {
          if (this.props.canSaveClaim) {
            this.props.saveClaim();
          }
        }, 100);
      },
    };

    this.fieldRefs = {};

    fieldHotkeys.forEach((hotkey) => {
      this.fieldRefs[hotkey.name] = {
        input: null,
      };
      this.fieldRefs[hotkey.name].setRef = (e) => {
        this.fieldRefs[hotkey.name] = e;
      };
      this.keyMap[hotkey.name] = hotkey.hotkey;
      this.keyHandlers[hotkey.name] = (e) => {
        e.preventDefault();
        this.fieldRefs[hotkey.name].focus();
        this.fieldRefs[hotkey.name].select();
      };
    });

    this.state = {
      advancedDisplay: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { focus } = this.props;
    if (prevProps.focus !== focus) {
      if (focus === "patient") {
        this.patientInput.focus();
      } else if (focus === "practitioner") {
        this.practitionerInput.focus();
      } else if (focus in this.fieldRefs) {
        this.fieldRefs[focus].focus();
        this.fieldRefs[focus].select();
      }
    }
  }

  componentDidMount() {
    this.fieldRefs["health_services_code"].focus();
  }

  onPatientAdded = () => {
    this.props.onError("Patient added");
  };

  onPractitionerAdded = () => {
    this.props.onError("Practitioner added");
  };

  onReferralPractitionerAdded = () => {
    this.props.onError("Practitioner added");
  };

  onAdvancedDisplayChange = (event) => {
    this.setState({
      advancedDisplay: event.target.checked,
    });
  };

  getFieldRef = (fieldName) => {
    if (fieldName in this.fieldRefs) {
      return this.fieldRefs[fieldName].setRef;
    }
    return null;
  };

  getField = (field, classes) => {
    return getInputField(
      {
        inputRef: this.getFieldRef(field.name),
        ...field,
      },
      classes
    );
  };

  render() {
    let classes = this.props.classes;
    const showAdvanced = !!(this.state.advancedDisplay || this.props.isEdit);

    return (
      <HotKeys keyMap={this.keyMap} handlers={this.keyHandlers}>
        <Grid container className={classes.root}>
          <Grid item md={9} xs={12}>
            <div className={classes.section}>
              <Paper className={classes.paper}>
                <Typography variant="h5">
                  {this.props.claimId
                    ? "Edit Claim #" + this.props.claimId
                    : "New Claim"}
                </Typography>
                <Typography variant="subtitle1" className={classes.estimate}>
                  {"Estimated Value: $" + intToPrice(this.props.estimatedPrice)}
                </Typography>
                {kClaimFields.map((fields, index) => {
                  return (
                    <Grid container key={index}>
                      {fields.map((field, index) => {
                        if (showAdvanced === false && field.isAdvanced) {
                          return <React.Fragment key={field.name} />;
                        }
                        return (
                          <Grid
                            item
                            md={4}
                            key={field.name}
                            className={classes.claimItem}
                          >
                            {this.getField(field, classes)}
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })}
                <div className={classes.resetPreferenceOpenContainer}>
                  <ClaimResetPreferencesOpenButton classes={classes} />
                </div>
                <div className={classes.newClaimButtonContainer}>
                  {!this.props.isEdit && (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.advancedDisplay}
                          onChange={this.onAdvancedDisplayChange}
                        />
                      }
                      label="Advanced"
                    />
                  )}
                  {!this.props.isEdit && <ClaimClearButton classes={classes} />}
                  {this.props.isEdit && <ClaimCancelButton classes={classes} />}
                  <ClaimSaveButton classes={classes} />
                </div>
              </Paper>
            </div>
          </Grid>
          <Grid item md={3} xs={12}>
            <div className={classes.section}>
              <PatientForm
                inputRef={this.setPatientInputRef}
                onAdd={this.onPatientAdded}
              />
            </div>
            <div className={classes.section}>
              <PractitionerForm
                inputRef={this.setPractitionerInputRef}
                onAdd={this.onPractitionerAdded}
              />
            </div>
            <div className={classes.section}>
              {showAdvanced && (
                <ReferralPractitionerForm
                  inputRef={this.setReferralPractitionerInputRef}
                  onAdd={this.onReferralPractitionerAdded}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </HotKeys>
    );
  }
}

class ClaimFormWithContext extends Component {
  static contextType = ClaimFormContext;

  render() {
    return (
      <ClaimForm
        {...this.props}
        claimId={this.context.claim.claim_id}
        estimatedPrice={this.context.estimatedPrice}
        canSaveClaim={this.context.canSaveClaim}
        saveClaim={this.context.saveClaim}
      />
    );
  }
}

export default withStyles(styles)(withData(ClaimFormWithContext));
