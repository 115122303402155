import * as React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';

import { withStyles} from '@material-ui/core/styles';

import { format } from 'date-fns';

import {
  kAllMetadata,
  kClaimMetadata,
  kPatientMetadata,
  kPractitionerMetadata,
  kClaimResponseMetadata
} from './Common.js';

import { getInputField } from './RemedyInput';

const styles = (theme: any) => {
  return {
    date: {
      width: '100%'
    },
    filterField: {
      padding: '16px',
    },
    filterTypography: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

const kFilterTypes = [
  {
    value : 'inc',
    title: 'Contains',
  },
  {
    value : 'notinc',
    title: 'Does Not Contain',
  },
  {
    value : 'eq',
    title: 'Equals',
  },
  {
    value : 'ne',
    title: 'Does Not Equal',
  },
  {
    value : 'lt',
    title: 'Is Less Than',
  },
  {
    value : 'gt',
    title: 'Is Greater Than',
  },
  {
    value : 'btwn',
    title: 'Is Between',
  },
];

const mapMetadata = (metadata: any) => {
  return metadata.filter((datum: any) => {
    return datum.fromClaimResponse === undefined;
  }).map((datum: any, index: number) => {
    return {
      title: datum.title,
      value: datum.name
    };
  });
};

const kFilterOptions = {
  'Claim' : mapMetadata(kClaimMetadata),
  'Patient' : mapMetadata(kPatientMetadata),
  'Practitioner' : mapMetadata(kPractitionerMetadata),
  'Claim Response' : mapMetadata(kClaimResponseMetadata),
};

const kSelectedFilterInit = {
  selectedFilter: 'applied_payment',
  selectedFilterType: 'inc',
  selectedFilterArg1: '',
  selectedFilterArg2: '',
};

const filterToText = (filter: any) => {
  const metadatum = kAllMetadata.find((datum: any) => datum.name === filter.name);
  const typeInfo = kFilterTypes.find((type: any) => type.value === filter.type);

  if (typeInfo === undefined || metadatum === undefined) {
    return 'FILTER ERROR';
  }

  let arg1 = filter.arg1;
  let arg2 = filter.arg2;

  if (metadatum.type === 'date') {
    arg1 = format(arg1, 'MM-dd-yyyy');
    arg2 = format(arg2, 'MM-dd-yyyy');
  }

  return (filter.subobj || 'Claim') + ' ' +
         metadatum.title + ' ' +
         typeInfo.title + ' ' +
         arg1 +
         (filter.type === 'btwn' ? ' and ' + arg2 : '')
}

class Filter extends React.Component<any, any> {

  public constructor(props: any) {
    super(props);

    this.state = {
      filters: [],
      ...kSelectedFilterInit
    };
  }

  public render() {
    const { classes } = this.props;

    const metadatum = kAllMetadata.find(
      (datum: any) => datum.name === this.state.selectedFilter
    );

    const filterFieldType = metadatum.type === 'date' ? 'date' : 'text';

    return (
      <React.Fragment>
        <Grid container={true} spacing={40}>
          <Grid item={true} md={2} xs={12} className={classes.filterField}>
            {getInputField({
              type: 'select',
              name: 'filter',
              title: '',
              value: this.state.selectedFilter,
              onChange: this.onFilterChange,
              options: kFilterOptions
            }, classes)}
          </Grid>
          <Grid item={true} md={1} xs={12} className={classes.filterField}>
            {getInputField({
                type: 'select',
                name: 'type',
                title: '',
                value: this.state.selectedFilterType,
                onChange: this.getOnFilterChange('selectedFilterType'),
                options: kFilterTypes
            }, classes)}
          </Grid>
          <Grid item={true} md={2} xs={4} className={classes.filterField}>
            {getInputField({
                type: filterFieldType,
                name: 'arg1',
                title: '',
                value: this.state.selectedFilterArg1,
                onChange: this.getOnFilterChange('selectedFilterArg1'),
                options: kFilterTypes
            }, classes)}
          </Grid>
          {this.state.selectedFilterType === 'btwn' && (
            <React.Fragment>
              <Grid item={true} md={1} xs={4} className={classes.filterTypography}>
                <Typography variant='body2'>
                  And
                </Typography>
              </Grid>
              <Grid item={true} md={2} xs={4} className={classes.filterField}>
                {getInputField({
                    type: filterFieldType,
                    name: 'arg2',
                    title: '',
                    value: this.state.selectedFilterArg2,
                    onChange: this.getOnFilterChange('selectedFilterArg2'),
                    options: kFilterTypes
                }, classes)}
              </Grid>
            </React.Fragment>
          )}
          <Grid item={true} md={2} xs={4} className={classes.filterField}>
            <Button onClick={this.onAddFilter}>
              Add Filter
            </Button>
          </Grid>
        </Grid>
        <React.Fragment>
          {this.state.filters.map((filter: any, index: number) => {
            return (
              <Grid container={true} key={index}>
                <Grid item={true} md={3} className={classes.filterTypography}>
                  <Typography variant='body2'>
                    { filterToText(filter) }
                  </Typography>
                </Grid>
                <Grid item={true} md={2}>
                  <IconButton onClick={this.getRemoveFilter(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </React.Fragment>
        <Button onClick={this.onApply}>
          { this.props.applyButtonText }
        </Button>
      </React.Fragment>
    );
  }

  private onFilterChange = (value: string) => {
    const metadatum = kAllMetadata.find(
      (datum: any) => datum.name === value
    );
    const argInit = metadatum.type === 'date' ? new Date() : '';
    this.setState({
      selectedFilter: value,
      selectedFilterArg1: argInit,
      selectedFilterArg2: argInit
    });
  }

  private getOnFilterChange(name: string) {
    return (value: any) => {
      this.setState({
        [name] : value
      });
    };
  }

  private onApply = () => {
    this.props.onApply(this.state.filters);
  }

  private onAddFilter = () => {
    this.setState((prevState: any) => {
      const metadatum = kAllMetadata.find((datum: any) => datum.name === prevState.selectedFilter);
      if (metadatum === undefined) {
        return;
      }
      const filters = prevState.filters;
      filters.push({
        name: prevState.selectedFilter,
        type: prevState.selectedFilterType,
        arg1: prevState.selectedFilterArg1,
        arg2: prevState.selectedFilterArg2,
        subobj: metadatum.subobj || 'Claim'
      });
      return {
        filters,
      };
    });
  }

  private getRemoveFilter = (index: number) => {
    return () => {
      this.setState((prevState: any) => {
        const filters = prevState.filters;
        filters.splice(index, 1);
        return {
          filters
        };
      });
    }
  }

}

export default withStyles(styles)(Filter);
