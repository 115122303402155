import React, { Component } from 'react';
import axios from 'axios';
import FileDownload from 'js-file-download';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const styles = {
  paper: {
    paddingTop: '16px',
    paddingBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
  }
};

class ReviewBatches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      batches : []
    };
  }

  componentWillMount = () => {
    this.getBatches();
  }

  getBatches = () => {
    axios.post('/api/v1/get_batch_status',
      {}
    ).then((response) => {
      this.setState({
        batches : response.data
      });
    }).catch((err) => {
    });
  }

  onCreateBatch = () => {
    axios.post('/api/v1/create_batch',
      {}
    ).then((response) => {
    }).catch((err) => {
    });
  }

  onCreateResendBatch = () => {
    axios.post('/api/v1/create_resend_batch',
      {}
    ).then((response) => {
    }).catch((err) => {
    });
  }

  getOnResendBatch = (batch_id) => {
    return () => {
      axios.post('/api/v1/resend_batch',
        { batch_id }
      ).then((response) => {
      }).catch((err) => {
      });
    };
  };

  getLatestBatch = () => {
    axios.post('/api/v1/get_latest_batch',
      {}
    ).then((response) => {
      FileDownload(response.data, 'batch.txt')
    }).catch((err) => {
    });
  }

  render() {
    let { classes } = this.props;

    return (
      <div>
        <Paper className={classes.paper}>
          <Typography variant='h5'>
            Batches
          </Typography>
          <div>
          {
            this.state.batches.map((batch, index) => {
              return (
                <span>
                  <Typography key={index}>
                    { 'Batch ' + batch.batch_id + ': ' +
                      (batch.accepted ? 'Accepted' : 'Pending') }
                  </Typography>
                  <Button
                    onClick={this.getOnResendBatch(batch.batch_id)}
                  >
                    Resend
                  </Button>
                </span>
              );
            })
          }
          </div>
          <Button onClick={this.onCreateBatch}>
            Create New
          </Button>
          <Button onClick={this.onCreateResendBatch}>
            Create Resend
          </Button>
          <Button onClick={this.getLatestBatch}>
            Download Latest
          </Button>
        </Paper>
      </div>
    );
  }
};

export default withStyles(styles)(ReviewBatches);

