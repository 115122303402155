import * as React from 'react';

import { withStyles} from '@material-ui/core/styles';

import Filter from '../../Components/Filter';

const styles = (theme: any) => {
  return {
  };
};

class ReviewPage extends React.Component {
  public render() {
    return (
      <React.Fragment>
        <Filter />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ReviewPage);
