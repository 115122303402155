import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@material-ui/icons/Check';

import { withStyles } from '@material-ui/core/styles';

import ClaimLoader from '../ClaimLoader';
import { withData } from './withData';

const styles = (theme: any) => {
  return {
    button: {
      position: 'absolute' as 'absolute',
    },
    buttonHolder: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '0px',
      position: 'relative' as 'relative',
    },
    checkIcon: {
      position: 'absolute' as 'absolute',
    },
    circle: {
      position: 'absolute' as 'absolute',
    },
    displayHolder: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    progress: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
      position: 'relative' as 'relative',
    },
    progressTitle: {
      paddingBottom: '60px',
    },
    root: {
      height: '20px'
    }
  };
};

interface IProps {
  claimEndpoint: any;
  classes: any;
  patientEndpoint: any;
  practitionerEndpoint: any;
}

class DataDisplay extends React.Component<IProps, any> {
  public render() {
    const {
      classes,
      claimEndpoint,
      patientEndpoint,
      practitionerEndpoint
    } = this.props;

    return (
      <React.Fragment>
        <Paper className={classes.root}>
          <Dialog open={!this.isLoaded()}>
            <DialogTitle>Loading...</DialogTitle>
            <Grid container={true}>
              { this.renderProgress('Claims', claimEndpoint.data) }
              { this.renderProgress('Patients', patientEndpoint.data) }
              { this.renderProgress('Practitioners', practitionerEndpoint.data) }
            </Grid>
          </Dialog>
          <Grid container={true}>
            <Grid item={true} md={2} />
            { this.renderField('Claims', claimEndpoint, true) }
            { this.renderField('Patients', patientEndpoint, false) }
            { this.renderField('Practitioners', practitionerEndpoint, false) }
            <Grid item={true} md={3} />
          </Grid>
        </Paper>
        { this.isLoaded() && this.props.children }
      </React.Fragment>
    );
  }

  private isLoaded() {
    return this.props.claimEndpoint.data !== null &&
           this.props.patientEndpoint.data !== null &&
           this.props.practitionerEndpoint.data !== null;
  }

  private renderProgress(typeName: string, data: any) {
    const { classes } = this.props;

    return (
      <Grid item={true} md={4} className={classes.progress}>
        <Typography className={classes.progressTitle}>
          { typeName }
        </Typography>
        { data !== null && <CheckIcon className={classes.checkIcon} /> }
        { data === null && <CircularProgress className={classes.circle}/> }
      </Grid>
    );
  }

  private renderField(typeName: string, endpoint: any, loadButton: boolean) {
    const { classes } = this.props;
    const { data } = endpoint;
    return (
      <React.Fragment>
        { loadButton && (
          <Grid item={true} md={1} className={classes.buttonHolder}>
            <ClaimLoader
              load={this.onClaimLoad}
            />
          </Grid>
        )}
        <Grid item={true} md={2} className={classes.displayHolder}>
          <Typography>
            { typeName + ' Loaded: ' + (data === null ? 0 : data.length) }
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }

  private onClaimLoad = (args: any) => {
    this.props.claimEndpoint.action(
      args,
      'load',
      null
    );
  }
}

export default withStyles(styles)(withData(DataDisplay));
